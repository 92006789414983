import {
  FdrFlightGlobalSearchStateType,
  GlobalSearchFlightPartition,
} from '@hotelplan/fdr.lib.search.state-config';
import {
  FdrFlightPartition,
  IFdrFlightSrlControlState,
} from 'fdr/components/local/fdr-flight-search/fdr-flight-search.types';
import {
  mapTravelDatesFormStateToTravelDatesGlobalState,
  mapTravelDatesGlobalStateToTravelDatesFormState,
  mapTravelRoomsFormStateToTravelRoomsGlobalState,
  mapTravelRoomsGlobalStateToTravelRoomsFormState,
} from 'fdr/lib/mappers/map-fdr-gss/map-fdr-gss';
import { getTodaysDate, isBefore } from '@hotelplan/libs.utils';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'fast-deep-equal';

export function mapFlightGlobalSearchStateToFormState(
  globalSearchState?: FdrFlightGlobalSearchStateType | null
): IFdrFlightSrlControlState {
  const formState = {} as IFdrFlightSrlControlState;

  if (!globalSearchState) return formState;

  if (globalSearchState.fp) {
    formState.flightPartitions = globalSearchState.fp.map(partition => {
      const formPartition = {} as FdrFlightPartition;

      if (partition.td) {
        const travelDates = mapTravelDatesGlobalStateToTravelDatesFormState(
          partition.td
        );

        // NOTE: If travel dates from the Flight Global Search State are outdated (~ in the past) we should ignore them.
        if (!isBefore(travelDates.departureDate, getTodaysDate())) {
          formPartition.travelDates = travelDates;
        }
      }

      if (partition.fc) {
        formPartition.flightClass = partition.fc;
      }

      return { ...formPartition };
    });
  }

  if (globalSearchState.ft) {
    formState.flightType = globalSearchState.ft;
  }

  if (globalSearchState.tr) {
    formState.travelRooms = mapTravelRoomsGlobalStateToTravelRoomsFormState(
      globalSearchState.tr
    );
  }

  return { ...formState };
}

function mapFlightSearchControlToFGSS(
  state: IFdrFlightSrlControlState
): FdrFlightGlobalSearchStateType {
  const fgss = {} as FdrFlightGlobalSearchStateType;

  if (!state) return fgss;

  if (state.flightType) {
    fgss.ft = state.flightType;
  }

  if (state.travelRooms) {
    fgss.tr = mapTravelRoomsFormStateToTravelRoomsGlobalState(
      state.travelRooms
    );
  }

  if (state.flightPartitions) {
    const partitionsToCheck =
      state.flightType === FdrFlightType.OpenJaw
        ? state.flightPartitions
        : state.flightPartitions?.slice(0, 1);

    const nextFlightPartitions = partitionsToCheck
      .map(partition => {
        const nextPartition = {} as GlobalSearchFlightPartition;

        if (partition.flightClass) {
          nextPartition.fc = partition.flightClass;
        }

        if (partition.travelDates) {
          nextPartition.td = mapTravelDatesFormStateToTravelDatesGlobalState(
            partition.travelDates
          );
        }

        return nextPartition;
      })
      .filter(partition => !isEmpty(partition));

    if (nextFlightPartitions.length) {
      fgss.fp = nextFlightPartitions;
    }
  }

  return fgss;
}

export function mapFormStateToFlightGlobalSearchState(
  defaultFormState: IFdrFlightSrlControlState,
  formState: IFdrFlightSrlControlState,
  currentState?: IFdrFlightSrlControlState
): FdrFlightGlobalSearchStateType {
  const resultFgss = {} as FdrFlightGlobalSearchStateType;
  const defaultFgss = mapFlightSearchControlToFGSS(defaultFormState);
  const nextFgss = mapFlightSearchControlToFGSS(formState);
  const currentFgss = mapFlightSearchControlToFGSS(currentState);

  if (
    nextFgss.ft &&
    (!isEqual(defaultFgss.ft, nextFgss.ft) ||
      isEqual(currentFgss.ft, nextFgss.ft))
  ) {
    resultFgss.ft = nextFgss.ft;
  }

  if (
    nextFgss.tr &&
    (!isEqual(defaultFgss.tr, nextFgss.tr) ||
      isEqual(currentFgss.tr, nextFgss.tr))
  ) {
    resultFgss.tr = nextFgss.tr;
  }

  if (nextFgss.fp && !isEqual(defaultFgss.fp, nextFgss.fp)) {
    const partitionsToCheck =
      nextFgss.ft === FdrFlightType.OpenJaw
        ? nextFgss.fp
        : nextFgss.fp?.slice(0, 1);

    const nextFlightPartitions = partitionsToCheck
      .map((partition, i) => {
        const nextPartition = {} as GlobalSearchFlightPartition;
        const defaultPartition = defaultFgss.fp ? defaultFgss.fp[i] : null;
        const currentPartition = currentFgss.fp ? currentFgss.fp[i] : null;

        if (
          partition.fc &&
          (!isEqual(defaultPartition?.fc, partition.fc) ||
            isEqual(currentPartition?.fc, partition.fc))
        ) {
          nextPartition.fc = partition.fc;
        }

        if (
          partition.td &&
          (!isEqual(defaultPartition?.td, partition.td) ||
            isEqual(currentPartition?.td, partition.td))
        ) {
          nextPartition.td = partition.td;
        }

        return nextPartition;
      })
      .filter(partition => !isEmpty(partition));

    if (nextFlightPartitions.length) {
      resultFgss.fp = nextFlightPartitions;
    }
  }

  return resultFgss;
}
