import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { FdrLinkOpenMethod, FdrLinkType } from '@hotelplan/supergraph-api';

const AgencyLink: React.FC<{
  children?: React.ReactNode;
  onClick(): void;
}> = props => {
  const [, { language }] = useTranslation('common');

  const agencyRouteProps = useMemo(() => {
    const route = getRouteByPageTypeLocale(EPageType.AgencyOverview, language);

    return route
      ? {
          url: route.builder({}),
          type: FdrLinkType.Internal,
          openMethod: FdrLinkOpenMethod.NewTab,
        }
      : null;
  }, [language]);

  return (
    <FdrRoute link={agencyRouteProps} onClick={props.onClick}>
      {props.children}
    </FdrRoute>
  );
};

export default AgencyLink;
