import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrMaintenanceNotificationFragment = {
  __typename?: 'FdrMaintenanceNotification';
  id: string;
  mainTitle: string;
  mainText: string;
  backgroundColor: string;
  closable?: boolean | null;
  notificationType: Types.FdrMaintenanceNotificationType;
  showIfClosed?: Types.FdrMaintenanceNotificationShowMode | null;
};

export const FdrMaintenanceNotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMaintenanceNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrMaintenanceNotification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notificationType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showIfClosed' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
