/* eslint-disable @typescript-eslint/no-unused-vars,i18next/no-literal-string */
import { FieldPolicy, TypePolicies, TypePolicy } from '@apollo/client';
import {
  FdrGeoOffer,
  FdrProductOffer,
  FdrThemeOffer,
} from '@hotelplan/supergraph-api';
import {
  FdrFlightMultiSelectFilterFragment,
  FdrMultiSelectFilterFragment,
} from 'fdr/schemas/fragment/filters/fdr-filters.generated';
import { KeyArgsReducer } from './apolloTypePolicies.types';
import {
  createFilterInactiveMerge,
  fdrAutocompleteMerge,
  fdrBookingsMerge,
  fdrSearchHistoryMerge,
  fdrVisitHistoryMerge,
} from './apolloTypePolicies.utils';

const possibleTypeNames = [
  `AccountContext`,
  `AccountLoginPage`,
  `AccountMutationResponse`,
  `AccountMyProfilePage`,
  `AccountPage`,
  `AccountPersonalDataResponse`,
  `AddressDetails`,
  `AddTravelCompanionResponse`,
  `Agency`,
  `AgencyAppointmentResponse`,
  `AgencyAutocomplete`,
  `AgencyContact`,
  `AgencyContactPhone`,
  `AgencyRecommendationGroup`,
  `Airline`,
  `Airport`,
  `AuthMethod`,
  `AuthMethodListResponse`,
  `AutocompleteComponent`,
  `BD4TravelRecommendation`,
  `BD4TravelRecommendationInfo`,
  `BD4TravelRecommendationTracking`,
  `BlogArticleRecommendationItem`,
  `BoardType`,
  `BookingBoardType`,
  `BookingDetails`,
  `BookingDetailsResponse`,
  `BookingExtraService`,
  `BookingHotelRoom`,
  `BookingItem`,
  `BookingResponse`,
  `BookingRoomType`,
  `BookingTransfer`,
  `Booster`,
  `BrandBox`,
  `BrandsComponent`,
  `Breadcrumb`,
  `BreadcrumbsComponent`,
  `BusinessUnit`,
  `Catalog`,
  `CatalogOrderResponse`,
  `CatalogOverviewContext`,
  `CheckboxFilterComponent`,
  `ClimateChart`,
  `ClimateChartEntry`,
  `ClimateComponent`,
  `ContactForm`,
  `ContactFormResponse`,
  `ContactInformation`,
  `ContactPageContext`,
  `ContactRecommendationGroup`,
  `CookieDisclaimer`,
  `DeleteHistoryResponse`,
  `DoubleRangeSliderFilterComponent`,
  `EmailAdvertisingGetEmailFromTokenResponse`,
  `EmailAdvertisingGetTokenFromEmailResponse`,
  `EmailAdvertisingRecommendationGroup`,
  `EmailAdvertisingRevocationConfirmationPageContext`,
  `EmailAdvertisingRevocationPageContext`,
  `EmailAdvertisingRevocationResponse`,
  `EmailAdvertisingStaticContent`,
  `EmployeeBox`,
  `EmployeesComponent`,
  `EmployeeSocialProfile`,
  `ExactTravelPeriod`,
  `ExternalLink`,
  `ExternalMediaItem`,
  `FaqComponent`,
  'FdrAccountBooking',
  'FdrAccountBookingDetailsPage',
  'FdrAccountBookingListItem',
  'FdrAccountBookingListResponse',
  'FdrAccountBookingsPage',
  'FdrAccountPages',
  'FdrAccountProfilePage',
  'FdrAccountPersonalDataResponse',
  'FdrAccountCustomer',
  'FdrAccountTraveller',
  'FdrAccountTransferBasketResponse',
  'FdrAccountUpsellTransfer',
  'FdrAccountUpsellTransfersResponse',
  'FdrAgency',
  'FdrAgencyOverviewPage',
  'FdrAirport',
  'FdrBooster',
  'FdrB2BLoginPage',
  'FdrContactPage',
  'FdrCatalogPage',
  'FdrContinent',
  'FdrCountry',
  'FdrCruise',
  'FdrResort',
  'FdrDestination',
  'FdrEmailAdvertisingPages',
  'FdrFlightHomePage',
  'FdrFlightOffer',
  'FdrFlightOfferRecommender',
  'FdrFlightOfferRecommender',
  'FdrFlightSearchControlsMultiSelectFilter',
  'FdrReturnFlightSearchControls',
  'FdrFlightSearchControlsResponse',
  'FdrFlightSearchControlsSingleSelectFilter',
  'FdrFlightSearchHistory',
  'FdrFlightSearchResultListPage',
  'FdrGeo',
  'FdrGeoOfferRecommender',
  'FdrGeoOfferRecommenderSearchResult',
  'FdrGeoOverviewPage',
  'FdrGeoSearchResult',
  'FdrGeoSearchResultResponse',
  'FdrHolidayFinderInitialOffersResponse',
  'FdrHolidayFinderInitialOffersSearchResult',
  'FdrHolidayFinderLandingPage',
  'FdrHolidayFinderNextOffersResponse',
  'FdrHolidayFinderOffer',
  'FdrHolidayFinderOffersSearchResult',
  'FdrHolidayFinderPages',
  'FdrHolidayFinderVotingPage',
  'FdrHomePage',
  'FdrHotel',
  'FdrImage',
  'FdrInfoNotification',
  'FdrLastActivity',
  'FdrLastActivityOffer',
  'FdrMaintenanceNotification',
  'FdrMarketingRecommender',
  'FdrMarketingTeaser',
  'FdrNewsletterPages',
  'FdrNewsletterSubscriptionConfirmationPage',
  'FdrNewsletterSubscriptionPage',
  'FdrNotFoundPage',
  'FdrNotificationsResponse',
  'FdrOrganization',
  'FdrProduct',
  'FdrProductFeature',
  'FdrProductFeatureGroup',
  'FdrProductOffer',
  'FdrProductOfferRecommender',
  'FdrProductOfferSearchResult',
  'FdrProductPriceDateOverviewResponse',
  'FdrProductSearchHistory',
  'FdrProductSearchResult',
  'FdrProductSearchResultListPage',
  'FdrProductWebMeta',
  'FdrProductMoreOffersLinkResponse',
  'FdrRawText',
  'FdrRegion',
  'FdrResizedImage',
  'FdrRssBlogArticleRecommender',
  'FdrRoundTrip',
  'FdrSearchControls',
  'FdrSearchControlsAccommodationSizeOption',
  'FdrSearchControlsAirlineOption',
  'FdrSearchControlsDepartureAirportOption',
  'FdrSearchControlsDepartureFlightArrivalTimeOption',
  'FdrSearchControlsDepartureFlightDepartureTimeOption',
  'FdrSearchControlsFlightDurationOption',
  'FdrSearchControlsFlightStopoverDurationOption',
  'FdrSearchControlsHpRatingOption',
  'FdrSearchControlsMaxPriceOption',
  'FdrSearchControlsMaxStopsOption',
  'FdrSearchControlsMealTypeOption',
  'FdrSearchControlsMinPriceOption',
  'FdrSearchControlsMultiSelectFilter',
  'FdrSearchControlsOneOptionFilter',
  'FdrSearchControlsProductFeatureOption',
  'FdrSearchControlsReturnFlightArrivalTimeOption',
  'FdrSearchControlsReturnFlightDepartureTimeOption',
  'FdrSearchControlsResponse',
  'FdrSearchControlsRoomTypeOption',
  'FdrSearchControlsSingleSelectFilter',
  'FdrSearchControlsTaRatingOption',
  'FdrSearchHistoryResponse',
  'FdrSmartSeerRecommender',
  'FdrSmartSeerRecommenderSearchResult',
  'FdrStaticPage',
  'FdrStaticPageLanguageAlternative',
  'FdrTextAutocompleteResponse',
  'FdrTheme',
  'FdrThemeOfferRecommender',
  'FdrThemeOfferRecommenderSearchResult',
  'FdrThemeOverviewPage',
  'FdrTrackedProductOffer',
  'FdrVideo',
  'FdrVisitHistory',
  'FdrVisitHistoryResponse',
  'FdrWishlist',
  'FdrWishlistItem',
  'FdrWishlistPage',
  `FilterComponent`,
  `FilterItem`,
  `FlexibleTravelPeriod`,
  `Flight`,
  `FlightBaggageInfo`,
  `FlightCheckoutComponent`,
  `FlightDestinationInfo`,
  `FlightHistoryResponse`,
  `FlightHomeRecommendationGroup`,
  `FlightOffer`,
  `FlightPartition`,
  `FlightRecommendationWithoutPriceItem`,
  `FlightRecommendationWithPriceItem`,
  `FlightSearchControlComponent`,
  `FlightSegment`,
  `FlightSrlComponent`,
  `FlightSrlContainer`,
  `FlightSrlRecommendationGroup`,
  `FlightStopOverDuration`,
  `GeoChildComponent`,
  `GeoChildrenComponent`,
  `GeoCoordinates`,
  `GeoDefaultGeoRecommendationsComponent`,
  `GeoFeature`,
  `GeoFeatureGroup`,
  `GeoInfoComponent`,
  `GeoLocation`,
  `GeoObject`,
  `GeoObjectRecommendationGroupComponent`,
  `GeoOverviewChildComponent`,
  `GeoOverviewContext`,
  `GeoOverviewRecommendationGroupComponent`,
  `GeoRecommendationItem`,
  `GroupOrlItem`,
  `HelpOverlayBox`,
  `HelpOverlayBoxChat`,
  `HelpOverlayBoxContact`,
  `HelpOverlayData`,
  `HeroMediaGallery`,
  `HistoryFlightRecord`,
  `HistoryProductRecord`,
  `HistorySrlRecord`,
  `HolidayFinderInfo`,
  `HolidayFinderLandingPage`,
  `HolidayFinderOffer`,
  `HolidayFinderPage`,
  `HolidayFinderPageContext`,
  `HolidayFinderProduct`,
  `HolidayFinderTracking`,
  `HolidayFinderVotingResponse`,
  `HomeRecommendationGroup`,
  `HomeStaticContent`,
  `HomeTitle`,
  `HotelDestinationInfo`,
  `IconMenuItem`,
  `Image`,
  `ImageMediaItem`,
  `InternalLink`,
  `LinkListComponent`,
  `LinkListItem`,
  `MapSuggestion`,
  `MarketingRecommendationItem`,
  `MediaGallery`,
  `Menu`,
  `MusicMediaItem`,
  `Mutation`,
  `NewsArticle`,
  `NewsArticlePage`,
  `NewsArticlesComponent`,
  `NewsArticlesFilter`,
  `NewsArticlesOverview`,
  `NewsArticlesOverviewPage`,
  `NewsletterConfirmationContext`,
  `NewsletterFinalizationContext`,
  `NewsletterGetEmailFromTokenResponse`,
  `NewsletterGetTokenFromEmailResponse`,
  `NewsletterRecommendationGroup`,
  `NewsletterSubscription`,
  `NewsletterSubscriptionContext`,
  `NewsletterSubscriptionResponse`,
  `NewsletterUnsubscriptionContext`,
  `NewsletterUnsubscriptionFinalizationContext`,
  `NewsletterUnsubscriptionResponse`,
  `Notification`,
  `NotificationInfo`,
  `NudgeItem`,
  `OpeningHours`,
  `OrlCheckoutComponent`,
  `OrlContext`,
  `OrlFlightAlternative`,
  `OrlGroupListComponent`,
  `OrlHistoryResponse`,
  `OrlIncludedInPriceComponent`,
  `OrlPriceExplanation`,
  `OrlPriceExplanationComponent`,
  `OrlRoom`,
  `OrlSearchContainer`,
  `OrlSingleListComponent`,
  `Page`,
  `PageB2BLoginData`,
  `PageFooterData`,
  `PageHeaderData`,
  `PageMetaData`,
  `PageNotFound404Data`,
  `PdfMediaItem`,
  `PDOItem`,
  `PdpContainer`,
  `PdpContext`,
  `PdpDescriptionComponent`,
  `PdpDestinationInfoComponent`,
  `PdpFeatureRating`,
  `PdpMapComponent`,
  `PdpMapHotel`,
  `PdpMoreOffersButton`,
  `PdpOverviewComponent`,
  `PdpPriceDateOverviewComponent`,
  `PdpRecommendationGroup`,
  `PdpTripAdvisorComponent`,
  `PhoneDetails`,
  `Price`,
  `ProductFeature`,
  `ProductFeatureGroup`,
  `ProductRecommendationItem`,
  `Query`,
  `RadiobuttonFilterComponent`,
  `ReasonsOfConfidence`,
  `ResizedImage`,
  `ResortTopHotelsComponent`,
  `RoomType`,
  `SearchControlComponent`,
  `Shift`,
  `SingleOrlItem`,
  `SingleValueFilterComponent`,
  `SliderFilterComponent`,
  `SrlEmptyResult`,
  `SrlGeoGroupItem`,
  `SrlGeoGroupResult`,
  `SrlGeoItem`,
  `SrlHistoryResponse`,
  `SrlMapGeoPin`,
  `SrlMapPinsComponent`,
  `SrlMapProductPin`,
  `SrlProductItem`,
  `SrlRecommendationGroupComponent`,
  `SrlResultContext`,
  `SrlSearchControlsContext`,
  `SrlSingleResult`,
  `SrlSortComponent`,
  `SrlSubGeoFilterComponent`,
  `SrlSubGeoItem`,
  `StaticContext`,
  `StaticRecommendationGroup`,
  `TextComponent`,
  `TextMenuItem`,
  `ThemeOverviewPage`,
  `ThemePageRecommendationGroup`,
  `ThemePreviewComponent`,
  `ThemeRecommendationItem`,
  `ThemeStaticContent`,
  `TransferDate`,
  `TransferDetailInfo`,
  `TransferFlightInfo`,
  `TransferHotelInfo`,
  `TransferInfo`,
  `TravelCompanion`,
  `TravelComponentResponse`,
  `TravelDestination`,
  `Traveller`,
  `TravellerInfo`,
  `Travellers`,
  `TravelPeriodComponent`,
  `TripAdvisorRating`,
  `TripAdvisorReview`,
  `TripAdvisorSubrating`,
  `UserFinalizationResponse`,
  `UserParamsFromTokenResponse`,
  `UserRegistrationResponse`,
  `UspBox`,
  `UspBoxesComponent`,
  `VideoMediaItem`,
  `WaitingScreen`,
] as const;

type TypeNames = typeof possibleTypeNames[number];

// add default behaviour to all types
const possibleTypePolicies: Record<TypeNames, TypePolicy> =
  possibleTypeNames.reduce(
    (policies, policyType) => ({
      ...policies,
      [policyType]: { keyFields: false, merge: true },
    }),
    {} as any
  );

const addAllArgsReducer: KeyArgsReducer = (res, args) => {
  return [...res, ...Object.keys(args)].filter((v, i, a) => a.indexOf(v) === i);
};

const excludeContextArgReducer: KeyArgsReducer = res => {
  return res.filter(key => key !== 'context');
};

const addContextLanguageArgReducer: KeyArgsReducer = res => {
  return ['context', ['language'], ...res];
};

const buildFieldPolicy = (reducers: KeyArgsReducer[]): FieldPolicy => ({
  keyArgs: (args, ctx) => {
    return reducers.reduce((keys, reducer) => {
      return reducer(keys, args, ctx);
    }, []);
  },
});

const defaultFieldPolicy = buildFieldPolicy([
  addAllArgsReducer,
  excludeContextArgReducer,
  addContextLanguageArgReducer,
]);

function assign<K extends TypeNames>(typeName: K, policy: TypePolicy) {
  Object.assign(
    (possibleTypePolicies[typeName] = possibleTypePolicies[typeName] || {}),
    policy
  );
}

assign('Query', {
  // merge: (existing, incoming, options) => {
  //   return options.mergeObjects(existing, incoming);
  // },
  fields: {
    account: defaultFieldPolicy,
    agency: defaultFieldPolicy,
    agencyContact: defaultFieldPolicy,
    agencyOverview: defaultFieldPolicy,
    boosters: defaultFieldPolicy,
    catalogOverview: defaultFieldPolicy,
    contact: defaultFieldPolicy,
    cookieDisclaimer: defaultFieldPolicy,
    emailAdvertisingRevocation: defaultFieldPolicy,
    emailAdvertisingRevocationConfirmation: defaultFieldPolicy,
    fdrGeo: defaultFieldPolicy,
    fdrTextAutocomplete: {
      keyArgs: ['input', ['input', 'type', 'selected']],
      merge: fdrAutocompleteMerge,
    },
    fdrVisitHistory: {
      keyArgs: ['input', ['filter']],
      merge: fdrVisitHistoryMerge,
    },
    fdrSearchHistory: {
      keyArgs: ['input', ['filter']],
      merge: fdrSearchHistoryMerge,
    },
    flightHome: defaultFieldPolicy,
    flightSrl: defaultFieldPolicy,
    flightWaitingScreen: defaultFieldPolicy,
    geo: defaultFieldPolicy,
    geoOverview: defaultFieldPolicy,
    helpOverlayData: defaultFieldPolicy,
    history: defaultFieldPolicy,
    holidayFinder: defaultFieldPolicy,
    home: defaultFieldPolicy,
    newsletterConfirmation: defaultFieldPolicy,
    newsletterSubscription: defaultFieldPolicy,
    newsletterFinalization: defaultFieldPolicy,
    newsletterUnsubscription: defaultFieldPolicy,
    newsletterUnsubscriptionFinalization: defaultFieldPolicy,
    notificationData: defaultFieldPolicy,
    notificationInfoData: defaultFieldPolicy,
    orl: defaultFieldPolicy,
    pageB2BLogin: defaultFieldPolicy,
    pageFooter: defaultFieldPolicy,
    pageHeader: defaultFieldPolicy,
    pageNotFound404: defaultFieldPolicy,
    pdp: defaultFieldPolicy,
    productWaitingScreen: defaultFieldPolicy,
    srl: defaultFieldPolicy,
    static: defaultFieldPolicy,
    theme: defaultFieldPolicy,
    themeOverview: defaultFieldPolicy,
  },
});

assign('AccountContext', {
  fields: {
    bookingDetails: {
      keyArgs: ['bookingId'],
    },
    upcomingBookings: {
      keyArgs: ['page'],
    },
    pastBookings: {
      keyArgs: ['page'],
    },
  },
});

assign('FdrAccountBookingsPage', {
  fields: {
    upcomingBookings: {
      keyArgs: false,
      merge: fdrBookingsMerge,
    },
    pastBookings: {
      keyArgs: false,
      merge: fdrBookingsMerge,
    },
  },
});

assign('Agency', { merge: false });

assign('Image', { merge: false });

assign('ImageMediaItem', { merge: false });

assign('OrlContext', {
  merge: function (existing, incoming, options) {
    const consolidated = { ...existing, ...incoming };
    const resultingMap = new Map();

    for (const item in consolidated) {
      const typename = consolidated[item].__typename;

      resultingMap.set(
        typename || item,
        [
          ...(resultingMap?.get(typename) || []),
          existing?.[item] && { [item]: existing?.[item] },
          incoming?.[item] && { [item]: incoming?.[item] },
        ].filter(Boolean)
      );
    }

    return Array.from(resultingMap.values()).reduce((agr, values) => {
      if (values.length === 1) {
        return { ...agr, ...values[0] };
      }

      const merged = values.reduce((inAgr, value) => {
        for (const key in value) {
          if (incoming[key]) {
            inAgr[key] = options.mergeObjects(
              existing?.[key] || {},
              incoming[key]
            );
          }
        }
        return inAgr;
      }, {});

      return { ...agr, ...merged };
    }, {});
  },
  fields: {
    checkout: {
      keyArgs: ['offerId', 'backUrl'],
    },
    filters: {
      keyArgs: [
        'searchCriteria',
        ['filter', 'searchControl', 'sort'],
        'travelType',
      ],
      merge: true,
    },
    priceDateOverview: {
      keyArgs: ['criteria'],
    },
    priceExplanation: {
      keyArgs: ['criteria'],
    },
    searchResult: {
      keyArgs: ['criteria'],
    },
    url: {
      keyArgs: ['criteria'],
    },
    quickHotelInfo: {
      keyArgs: ['criteria'],
    },
  },
});

assign('OrlSearchContainer', {
  fields: {
    flightAlternatives: {
      keyArgs: ['offerId'],
    },
    single: {
      keyArgs: ['groupId'],
    },
  },
});

// todo: test keyFields, merge
assign('PdpContainer', {
  // merge: (existing, incoming) => {
  //   return { ...incoming };
  // },
  // keyFields: (object, { keyObject }) => {
  //   // console.log(object, keyObject);
  //   return [];
  // },
});

assign('PdpContext', {
  fields: {
    autocomplete: {
      keyArgs: ['criteria'],
    },
    dynamicComponents: {
      keyArgs: ['criteria'],
    },
    url: {
      keyArgs: [
        'targetPage',
        'searchCriteria',
        ['searchControl', 'filter', 'sort', 'page', ['groupId']],
      ],
    },
  },
});

assign('ProductFeatureGroup', { merge: false });

assign('ProductFeature', { merge: false });

assign('ResizedImage', { merge: false });

assign('FdrResizedImage', { merge: false, keyFields: false });

assign('SrlMapProductPin', { merge: false });

assign('SrlResultContext', {
  fields: {
    geoGroup: {
      keyArgs: ['groupId', 'page'],
    },
  },
});

assign('FdrWishlist', {
  keyFields: ['id'],
  fields: {
    wishlistItems: {
      merge(existing, incoming) {
        return incoming || existing || [];
      },
    },
  },
});

const filterInactiveProductOffersMerge =
  createFilterInactiveMerge<FdrProductOffer>(({ product }) => product);

assign('FdrProductSearchResult', {
  fields: {
    offersPage: {
      merge: filterInactiveProductOffersMerge,
    },
  },
});

assign('FdrHolidayFinderOffersSearchResult', {
  fields: {
    productOffers: {
      merge: filterInactiveProductOffersMerge,
    },
  },
});

assign('FdrProductOfferSearchResult', {
  fields: {
    offersPage: {
      merge: filterInactiveProductOffersMerge,
    },
  },
});

assign('FdrSmartSeerRecommenderSearchResult', {
  fields: {
    offersPage: {
      merge: filterInactiveProductOffersMerge,
    },
  },
});

assign('FdrProductPriceDateOverviewResponse', {
  fields: {
    offers: {
      merge: filterInactiveProductOffersMerge,
    },
  },
});

const filterInactiveGeoOffersMerge = createFilterInactiveMerge<FdrGeoOffer>(
  ({ topOffer }) => (topOffer ? topOffer.product : {})
);

assign('FdrGeoSearchResult', {
  fields: {
    geoOffers: {
      merge: filterInactiveGeoOffersMerge,
    },
  },
});

assign('FdrGeoOfferRecommenderSearchResult', {
  fields: {
    geoOffers: {
      merge: filterInactiveGeoOffersMerge,
    },
  },
});

assign('FdrThemeOfferRecommenderSearchResult', {
  fields: {
    themeOffers: {
      merge: createFilterInactiveMerge<FdrThemeOffer>(({ offer }) =>
        offer ? offer.product : {}
      ),
    },
  },
});

const filterInactiveFilterOptionsMerge = createFilterInactiveMerge<
  FdrMultiSelectFilterFragment['options'][number]
>(option => {
  switch (option.__typename) {
    case 'FdrSearchControlsAirlineOption':
      return option.airline;
    case 'FdrSearchControlsDepartureAirportOption':
      return option.airport;
  }
  return {};
});

assign('FdrSearchControlsMultiSelectFilter', {
  fields: {
    options: {
      merge: filterInactiveFilterOptionsMerge,
    },
  },
});

assign('FdrSearchControlsSingleSelectFilter', {
  fields: {
    options: {
      merge: filterInactiveFilterOptionsMerge,
    },
  },
});

const filterInactiveFlightFilterOptionsMerge = createFilterInactiveMerge<
  FdrFlightMultiSelectFilterFragment['options'][number]
>(option => {
  switch (option.__typename) {
    case 'FdrSearchControlsAirlineOption':
      return option.airline;
  }
  return {};
});

assign('FdrFlightSearchControlsMultiSelectFilter', {
  fields: {
    options: {
      merge: filterInactiveFlightFilterOptionsMerge,
    },
  },
});

assign('FdrFlightSearchControlsSingleSelectFilter', {
  fields: {
    options: {
      merge: filterInactiveFlightFilterOptionsMerge,
    },
  },
});

export const apolloTypePolicies: TypePolicies = { ...possibleTypePolicies };
