import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrAccountCustomerFragmentDoc } from 'fdr/schemas/fragment/account/fdr-account-customer.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAccountPersonalProfileQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrAccountPersonalProfileQuery = {
  __typename?: 'Query';
  fdrAccount?: {
    __typename?: 'FdrAccountPages';
    profile?: {
      __typename?: 'FdrAccountProfilePage';
      personalData: {
        __typename?: 'FdrAccountPersonalDataResponse';
        message?: string | null;
        accountCustomer?: {
          __typename?: 'FdrAccountCustomer';
          salutation?: Types.FdrSalutation | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          birthdate?: any | null;
          nationality?: string | null;
          agencyInfo?: string | null;
          addressDetails?: {
            __typename?: 'FdrAccountAddressDetails';
            street?: string | null;
            zip?: string | null;
            city?: string | null;
            country?: string | null;
          } | null;
          phone?: {
            __typename?: 'FdrAccountPhone';
            country?: string | null;
            phone?: string | null;
          } | null;
          mobilePhone?: {
            __typename?: 'FdrAccountPhone';
            country?: string | null;
            phone?: string | null;
          } | null;
        } | null;
      };
    } | null;
  } | null;
};

export const FdrAccountPersonalProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrAccountPersonalProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountCustomer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'fdrAccountCustomer',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAccountCustomerFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrAccountPersonalProfileQuery__
 *
 * To run a query within a React component, call `useFdrAccountPersonalProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrAccountPersonalProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrAccountPersonalProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrAccountPersonalProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrAccountPersonalProfileQuery,
    FdrAccountPersonalProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrAccountPersonalProfileQuery,
    FdrAccountPersonalProfileQueryVariables
  >(FdrAccountPersonalProfileDocument, options);
}
export function useFdrAccountPersonalProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrAccountPersonalProfileQuery,
    FdrAccountPersonalProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrAccountPersonalProfileQuery,
    FdrAccountPersonalProfileQueryVariables
  >(FdrAccountPersonalProfileDocument, options);
}
export type FdrAccountPersonalProfileQueryHookResult = ReturnType<
  typeof useFdrAccountPersonalProfileQuery
>;
export type FdrAccountPersonalProfileLazyQueryHookResult = ReturnType<
  typeof useFdrAccountPersonalProfileLazyQuery
>;
export type FdrAccountPersonalProfileQueryResult = Apollo.QueryResult<
  FdrAccountPersonalProfileQuery,
  FdrAccountPersonalProfileQueryVariables
>;
