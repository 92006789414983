import {
  FdrPriceCriteriaInput,
  FdrStopOverDuration,
  FdrTimeRangeInput,
} from '@hotelplan/supergraph-api';
import {
  FdrFilterOptionFragment,
  FdrFlightFilterOptionFragment,
} from 'fdr/schemas/fragment/filters/fdr-filters.generated';

export type RadiusFilter = {
  center: {
    latitude: number;
    longitude: number;
  };
  label?: string;
  radius: number;
};

export enum EFilterIds {
  MOST_POPULAR = `most-popular`,

  MAIN_FEATURES = `main-features`,
  MEAL_TYPES = `meal-types`,
  ROOM_TYPES = `room-types`,
  ACCOMMODATION_SIZE = `accommodation-size`,
  DEPARTURE_AIRPORTS = `departure-airports`,
  AIRLINES = `airlines`,
  OTHER_FEATURES = `other-features`,
  CULTURE_FEATURES = `culture-features`,
  ENVIRONMENT_FEATURES = `environment-features`,
  HOTEL_FEATURES = `hotel-features`,
  WELLNESS_FEATURES = `wellness-features`,
  SPORT_FEATURES = `sport-features`,
  DISTANCE_FEATURES = `distance-features`,
  BEACH_FEATURES = `beach-features`,
  FAMILY_FEATURES = `family-features`,

  FLIGHT_DURATION = `flight-duration`,
  MAX_STOPS = `max-stops`,
  PRICE = `price`,

  HP_RATINGS = `hp-ratings`,
  TA_RATINGS = `ta-ratings`,

  DEPARTURE_FLIGHT_TIME = `departure-flight-time`,
  RETURN_FLIGHT_TIME = `return-flight-time`,

  DEPARTURE_FLIGHT_DEPARTURE_TIME = `departure-flight-departure-time`,
  DEPARTURE_FLIGHT_ARRIVAL_TIME = `departure-flight-arrival-time`,

  RETURN_FLIGHT_ARRIVAL_TIME = `return-flight-arrival-time`,
  RETURN_FLIGHT_DEPARTURE_TIME = `return-flight-departure-time`,

  RADIUS = `radius`,
  FLIGHT_STOPOVER_DURATION = `flight-stopover-duration`,
}

export type TMultiSelection = {
  updated: TNormalizedOption[];
  value: string[] | null;
};

export type TSingleSelection<
  T extends
    | string
    | number
    | null
    | FdrTimeRangeInput
    | FdrPriceCriteriaInput
    | TStopOverDurationValue
> = {
  updated: TNormalizedOption;
  value: T;
};

export type TPriceSelection = {
  updated: TNormalizedOption[];
  value: {
    min: number;
    max: number;
  };
};

export interface IFdrFiltersFormState {
  /** Selected popular filter ids */
  [EFilterIds.MOST_POPULAR]?: TMultiSelection;
  /** Selected room type ids */
  [EFilterIds.ROOM_TYPES]?: TMultiSelection;
  [EFilterIds.ACCOMMODATION_SIZE]?: TMultiSelection;
  /** Selected board type ids */
  [EFilterIds.MEAL_TYPES]?: TMultiSelection;

  /** Selected main product feature ids */

  [EFilterIds.MAIN_FEATURES]?: TMultiSelection;
  [EFilterIds.FAMILY_FEATURES]?: TMultiSelection;
  [EFilterIds.BEACH_FEATURES]?: TMultiSelection;
  [EFilterIds.DISTANCE_FEATURES]?: TMultiSelection;
  [EFilterIds.SPORT_FEATURES]?: TMultiSelection;
  [EFilterIds.WELLNESS_FEATURES]?: TMultiSelection;
  [EFilterIds.HOTEL_FEATURES]?: TMultiSelection;
  [EFilterIds.ENVIRONMENT_FEATURES]?: TMultiSelection;
  [EFilterIds.CULTURE_FEATURES]?: TMultiSelection;
  // refundableFeatures?: Maybe<Array<string>>; ????? @todo: check if exsists

  /** Selected minimal TripAdvisor number */
  [EFilterIds.TA_RATINGS]?: TSingleSelection<number>;
  /** Selected minimal Hotelplan rating id */
  [EFilterIds.HP_RATINGS]?: TSingleSelection<number>;

  /** Selected max price per person */
  [EFilterIds.PRICE]?: TPriceSelection;
  [EFilterIds.FLIGHT_DURATION]?: TSingleSelection<number>;

  /** Selected stop overs number */
  [EFilterIds.MAX_STOPS]?: TSingleSelection<number>;

  /** Selected maximum flight duration in minutes */
  // maxFlightDuration?: Maybe<string>; // flightDuration @todo

  /** Selected departure airport platform ids */
  [EFilterIds.DEPARTURE_AIRPORTS]?: TMultiSelection;

  prevChangedFilter?: EFilterIds | null;

  /** Selected flight stopover duration ids */
  // flightStopOverDuration?: Maybe<StopOverDurationType>; @todo

  [EFilterIds.DEPARTURE_FLIGHT_TIME]?: TSingleSelection<FdrTimeRangeInput>;
  [EFilterIds.RETURN_FLIGHT_TIME]?: TSingleSelection<FdrTimeRangeInput>;

  [EFilterIds.DEPARTURE_FLIGHT_DEPARTURE_TIME]?: TSingleSelection<FdrTimeRangeInput>;
  [EFilterIds.DEPARTURE_FLIGHT_ARRIVAL_TIME]?: TSingleSelection<FdrTimeRangeInput>;

  [EFilterIds.RETURN_FLIGHT_ARRIVAL_TIME]?: TSingleSelection<FdrTimeRangeInput>;
  [EFilterIds.RETURN_FLIGHT_DEPARTURE_TIME]?: TSingleSelection<FdrTimeRangeInput>;

  [EFilterIds.FLIGHT_STOPOVER_DURATION]?: TSingleSelection<TStopOverDurationValue>;

  /** Selected direct flight arrival time id */
  // directFlightArrivalTime?: Maybe<string>;  @todo

  [EFilterIds.AIRLINES]?: TMultiSelection;

  /** Selected return flight arrival time id */
  // returnFlightArrivalTime?: Maybe<string>;  @todo
  /** Selected flight airlines ids */
  // flightAirlines?: Maybe<Array<string>>;  @todo

  // Url filters:
  // arrivalAirports?: Maybe<Array<string>>;  @todo
  // arrivalWeekdays?: Maybe<Array<string>>; @todo
  // departureWeekdays?: Maybe<Array<string>>; @todo
  // minPrice?: Maybe<string>; @todo
  // productCode?: Maybe<string>; @todo
  // provider?: Maybe<string>; @todo
  // flightProvider?: Maybe<string>; @todo

  // Map filters:
  [EFilterIds.RADIUS]?: RadiusFilter;
}

export enum ENormalizedFilterType {
  GROUPED = 'grouped',
  SINGLE_SELECT = 'single-select',
  MULTI_SELECT = 'multi-select',
  ONE_OPTIONAL = 'one-optional',
}

export type TStopOverDurationValue = {
  available: FdrStopOverDuration;
  selected: FdrStopOverDuration;
};

export type TBeAcceptableValues =
  | string
  | number
  | { min: string; max: string }
  | TStopOverDurationValue;

export type TNormalizedOption = {
  id: string;
  __typename: string;
  label: string;
  beValue: TBeAcceptableValues;
  count?: number;
  visible: boolean;
  disabled?: boolean;
  selected: boolean;
  isMissingCaption: boolean;
  isMissingCount: boolean;
  __tmp_rawOption: T_Union_FilterOption;
};

export type TNormalizedOneOptionFilter = {
  id: EFilterIds;
  name: string;
  visible: boolean;
  type: ENormalizedFilterType.ONE_OPTIONAL;
  option: TNormalizedOption;
  __hash: string;
};

export type TNormalizedMultiSelectFilter = {
  id: EFilterIds;
  name: string;
  visible: boolean;
  type: ENormalizedFilterType.MULTI_SELECT;
  options: TNormalizedOption[];
  __hash: string;
};

export type TNormalizedSingleSelectFilter = {
  id: EFilterIds;
  name: string;
  visible: boolean;
  type: ENormalizedFilterType.SINGLE_SELECT;
  options: TNormalizedOption[];
  __hash: string;
};

export type TGroupedFilter = {
  id: EFilterIds;
  name: string;
  visible: boolean;
  type: ENormalizedFilterType.GROUPED;
  grouped: TNormalizedSingleSelectFilter[];
  __hash: string;
};

export type TNormalizedFilter =
  | TNormalizedSingleSelectFilter
  | TNormalizedMultiSelectFilter
  | TNormalizedOneOptionFilter
  | TGroupedFilter;

export type T_Union_FilterOption =
  | FdrFilterOptionFragment
  | FdrFlightFilterOptionFragment;
