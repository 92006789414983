import React from 'react';
import { FdrLink } from '@hotelplan/supergraph-api';
import { AgencyContactFormModal } from './fdr-agency-appointment.styles';

type TProps = {
  closeModal(): void;
  link: FdrLink;
};

const FdrAgencyAppointment: React.FC<TProps> = ({
  closeModal,
  link,
}: TProps) => {
  const handleCloseModal = () => {
    requestAnimationFrame(() => {
      closeModal();
    });
  };

  return (
    <>
      <AgencyContactFormModal
        name="agencyContactForm"
        title=""
        fullScreen={true}
        show={true}
        onClose={handleCloseModal}
        className={link ? 'iframed' : ''}
      >
        <iframe src={link.url} title={link.url} />
      </AgencyContactFormModal>
    </>
  );
};

export default FdrAgencyAppointment;
