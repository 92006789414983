import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrFooterSocialMediaFragmentDoc } from './fdr-footer-social-media.generated';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
import { FdrMenuFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-menu.generated';
import { FdrFooterPaymentMethodFragmentDoc } from './fdr-footer-payment-method.generated';
import { FdrFooterBrandLogoFragmentDoc } from './fdr-footer-brand-logo.generated';
import { FdrFooterContactInformationFragmentDoc } from './fdr-footer-contact-information.generated';
export type FdrFooterFragment = {
  __typename?: 'FdrFooter';
  reasonsOfConfidenceMainTitle?: string | null;
  reasonsOfConfidenceMainText?: string | null;
  socialMedias?: Array<{
    __typename?: 'FdrFooterSocialMediaItem';
    icon: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      desaturated?: boolean | null;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    };
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  }> | null;
  menus: Array<{
    __typename?: 'FdrMenu';
    caption: string;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
    menuItems?: Array<{
      __typename?: 'FdrMenuItem';
      caption: string;
      iconType?: Types.FdrMenuIconType | null;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }> | null;
  }>;
  paymentMethods?: Array<{
    __typename?: 'FdrFooterPaymentMethodItem';
    link?: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    } | null;
    icon: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      desaturated?: boolean | null;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    };
  }> | null;
  brandLogos?: Array<{
    __typename?: 'FdrFooterBrandLogoItem';
    link?: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    } | null;
    icon: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      desaturated?: boolean | null;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    };
  }> | null;
  logo?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  contactInformation?: {
    __typename?: 'FdrContactInformation';
    timezone: string;
    phoneNumber: string;
    mainTitle?: string | null;
    openingHours?: {
      __typename?: 'FdrOpeningHours';
      monday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      tuesday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      wednesday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      thursday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      friday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      saturday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      sunday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
    } | null;
    link?: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    } | null;
  } | null;
};

export const FdrFooterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFooter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFooter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMedias' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFooterSocialMedia' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'menus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMenu' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethods' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFooterPaymentMethod' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brandLogos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFooterBrandLogo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '173' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '42' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '1x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '173' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '60' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_mobile',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '346' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '84' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'StringValue',
                                  value: '2x_desktop',
                                  block: false,
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'width' },
                                value: { kind: 'IntValue', value: '346' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'height' },
                                value: { kind: 'IntValue', value: '120' },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'mode' },
                                value: { kind: 'EnumValue', value: 'FIT' },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonsOfConfidenceMainTitle' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reasonsOfConfidenceMainText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInformation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFooterContactInformation' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFooterSocialMediaFragmentDoc.definitions,
    ...FdrMenuFragmentDoc.definitions,
    ...FdrFooterPaymentMethodFragmentDoc.definitions,
    ...FdrFooterBrandLogoFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrFooterContactInformationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
