import { FdrProductTravelType } from '@hotelplan/supergraph-api';

export enum TFdrAdditionTabTravelType {
  Apartment = 'APARTMENT',
  Cruise = 'CRUISE',
  Roundtrip = 'ROUNDTRIP',
}

export enum ELegacySearchControlTravelType {
  Package = 'PACKAGE',
  Hotel = 'HOTEL',
  Flight = 'FLIGHT',
  Roundtrip = 'ROUNDTRIP',
  Cruise = 'CRUISE',
  Apartment = 'APARTMENT',
}

export enum EFdrFlightTravelType {
  Flight = 'FLIGHT',
}

export type T_Custom_FdrTravelTypeUnit =
  | FdrProductTravelType
  | EFdrFlightTravelType;

export type T_Custom_TabsTravelTypesUnit =
  | TFdrAdditionTabTravelType
  | FdrProductTravelType
  | EFdrFlightTravelType;

export type U_Extended_TravelType = {
  fdr: T_Custom_FdrTravelTypeUnit | null;
};
