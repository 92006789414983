import useFdrAgencyData from 'fdr/components/local/fdr-agency/useFdrAgencyData';
import { useFdrHelpOverlayQuery } from 'fdr/schemas/query/help-overlay/fdr-help-overlay.generated';

export const useFdrHelpOverlayData = () => {
  const { data: fdrHelpOverlayData, loading: helpOverlayLoading } =
    useFdrHelpOverlayQuery({
      ssr: false,
    });

  const {
    data: fdrAgencyData,
    loading: agencyLoading,
    noAgencyId,
  } = useFdrAgencyData();

  const fdrBoxes = fdrHelpOverlayData?.fdrHelpOverlay?.boxes;

  return {
    boxes: fdrBoxes,
    agency: fdrAgencyData?.fdrAgency,
    loading: helpOverlayLoading || agencyLoading,
    noAgencyId,
  };
};
