import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAccountCustomerAgencyQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrAccountCustomerAgencyQuery = {
  __typename?: 'Query';
  fdrAccount?: {
    __typename?: 'FdrAccountPages';
    profile?: {
      __typename?: 'FdrAccountProfilePage';
      personalData: {
        __typename?: 'FdrAccountPersonalDataResponse';
        accountCustomer?: {
          __typename?: 'FdrAccountCustomer';
          agencyInfo?: string | null;
        } | null;
      };
    } | null;
  } | null;
};

export const FdrAccountCustomerAgencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrAccountCustomerAgency' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountCustomer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'agencyInfo' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrAccountCustomerAgencyQuery__
 *
 * To run a query within a React component, call `useFdrAccountCustomerAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrAccountCustomerAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrAccountCustomerAgencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrAccountCustomerAgencyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrAccountCustomerAgencyQuery,
    FdrAccountCustomerAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrAccountCustomerAgencyQuery,
    FdrAccountCustomerAgencyQueryVariables
  >(FdrAccountCustomerAgencyDocument, options);
}
export function useFdrAccountCustomerAgencyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrAccountCustomerAgencyQuery,
    FdrAccountCustomerAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrAccountCustomerAgencyQuery,
    FdrAccountCustomerAgencyQueryVariables
  >(FdrAccountCustomerAgencyDocument, options);
}
export type FdrAccountCustomerAgencyQueryHookResult = ReturnType<
  typeof useFdrAccountCustomerAgencyQuery
>;
export type FdrAccountCustomerAgencyLazyQueryHookResult = ReturnType<
  typeof useFdrAccountCustomerAgencyLazyQuery
>;
export type FdrAccountCustomerAgencyQueryResult = Apollo.QueryResult<
  FdrAccountCustomerAgencyQuery,
  FdrAccountCustomerAgencyQueryVariables
>;
