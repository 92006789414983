import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrHelpOverlayQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrHelpOverlayQuery = {
  __typename?: 'Query';
  fdrHelpOverlay?: {
    __typename?: 'FdrHelpOverlay';
    id: string;
    boxes?: Array<
      | {
          __typename?: 'FdrHelpOverlayBox';
          id: string;
          mainText: string;
          mainTitle: string;
          targetUrl?: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          } | null;
        }
      | {
          __typename?: 'FdrHelpOverlayBoxChat';
          id: string;
          mainText: string;
          mainTitle: string;
          targetUrl?: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          } | null;
        }
      | {
          __typename?: 'FdrHelpOverlayBoxPhone';
          id: string;
          mainText: string;
          mainTitle: string;
          additionalInformation?: string | null;
          targetUrl?: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          } | null;
        }
    > | null;
  } | null;
};

export type FdrHelpOverlayBoxItemFdrHelpOverlayBoxFragment = {
  __typename?: 'FdrHelpOverlayBox';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export type FdrHelpOverlayBoxItemFdrHelpOverlayBoxChatFragment = {
  __typename?: 'FdrHelpOverlayBoxChat';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export type FdrHelpOverlayBoxItemFdrHelpOverlayBoxPhoneFragment = {
  __typename?: 'FdrHelpOverlayBoxPhone';
  id: string;
  mainText: string;
  mainTitle: string;
  additionalInformation?: string | null;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export type FdrHelpOverlayBoxItemFragment =
  | FdrHelpOverlayBoxItemFdrHelpOverlayBoxFragment
  | FdrHelpOverlayBoxItemFdrHelpOverlayBoxChatFragment
  | FdrHelpOverlayBoxItemFdrHelpOverlayBoxPhoneFragment;

export type FdrHelpOverlayBoxFragment = {
  __typename?: 'FdrHelpOverlayBox';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export type FdrHelpOverlayBoxChatFragment = {
  __typename?: 'FdrHelpOverlayBoxChat';
  id: string;
  mainText: string;
  mainTitle: string;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export type FdrHelpOverlayBoxPhoneFragment = {
  __typename?: 'FdrHelpOverlayBoxPhone';
  id: string;
  mainText: string;
  mainTitle: string;
  additionalInformation?: string | null;
  targetUrl?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
};

export const FdrHelpOverlayBoxFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBox' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBox' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHelpOverlayBoxChatFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBoxChat' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBoxChat' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHelpOverlayBoxPhoneFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBoxPhone' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBoxPhone' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetUrl' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'additionalInformation' },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHelpOverlayBoxItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHelpOverlayBoxItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHelpOverlayBoxItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrHelpOverlayBox' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrHelpOverlayBoxChat' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrHelpOverlayBoxPhone' },
          },
        ],
      },
    },
    ...FdrHelpOverlayBoxFragmentDoc.definitions,
    ...FdrHelpOverlayBoxChatFragmentDoc.definitions,
    ...FdrHelpOverlayBoxPhoneFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrHelpOverlayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrHelpOverlay' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrHelpOverlay' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'boxes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHelpOverlayBox' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHelpOverlayBoxChat' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrHelpOverlayBoxPhone' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHelpOverlayBoxFragmentDoc.definitions,
    ...FdrHelpOverlayBoxChatFragmentDoc.definitions,
    ...FdrHelpOverlayBoxPhoneFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrHelpOverlayQuery__
 *
 * To run a query within a React component, call `useFdrHelpOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrHelpOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrHelpOverlayQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrHelpOverlayQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrHelpOverlayQuery,
    FdrHelpOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrHelpOverlayQuery,
    FdrHelpOverlayQueryVariables
  >(FdrHelpOverlayDocument, options);
}
export function useFdrHelpOverlayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrHelpOverlayQuery,
    FdrHelpOverlayQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrHelpOverlayQuery,
    FdrHelpOverlayQueryVariables
  >(FdrHelpOverlayDocument, options);
}
export type FdrHelpOverlayQueryHookResult = ReturnType<
  typeof useFdrHelpOverlayQuery
>;
export type FdrHelpOverlayLazyQueryHookResult = ReturnType<
  typeof useFdrHelpOverlayLazyQuery
>;
export type FdrHelpOverlayQueryResult = Apollo.QueryResult<
  FdrHelpOverlayQuery,
  FdrHelpOverlayQueryVariables
>;
