import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { mapOpeningHoursToSchedules } from '@hotelplan/fdr.lib.misc.schedule';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { ClickEventType, trackClicks } from '@hotelplan/fdr.lib.track.meta';
import { expandChat } from '@hotelplan/fdr.regular.fusion.fdr-chat';
import { FdrSchedule } from '@hotelplan/fdr.regular.fusion.fdr-schedule';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { FdrLinkOpenMethod, FdrLinkType } from '@hotelplan/supergraph-api';
import { FdrAgencyAppointment } from 'fdr/components/local/fdr-agency-appointment';
import { FdrChat } from 'fdr/components/local/fdr-chat';
import { FdrAgencyFragment } from 'fdr/schemas/fragment/agency/fdr-agency.generated';
import { FdrFooterContactInformationFragment } from 'fdr/schemas/fragment/footer/fdr-footer-contact-information.generated';
import {
  ChatContent,
  ChatIcon,
  ChatLink,
  ContactPageContainer,
  ContactPageLink,
  EmailContent,
  EmailIcon,
  EmailLink,
  FooterContactsTitle,
  FooterContactsWrapper,
  PhoneContent,
  PhoneIcon,
  PhoneLink,
} from './fdr-footer-contacts.styles';

const { defaultAgencyId } = getConfig().publicRuntimeConfig;

const phoneClickHandler = () =>
  trackClicks({
    type: ClickEventType.CALL,
    payload: { source: 'footer' },
  });

const appointmentClickHandler = () =>
  trackClicks({
    type: ClickEventType.APPOINTMENT,
    payload: { source: 'footer' },
  });

const emailClickHandler = () =>
  trackClicks({
    type: ClickEventType.EMAIL,
    payload: { source: 'footer' },
  });

const contactClickHandler = () =>
  trackClicks({
    type: ClickEventType.CONTACT,
    payload: { source: 'footer' },
  });

const chatClickHandler = () =>
  trackClicks({
    type: ClickEventType.CHAT,
    payload: { source: 'footer' },
  });

interface IFdrFooterContactsProps {
  agency: FdrAgencyFragment;
  defaultContactInfo: FdrFooterContactInformationFragment | null;
}

const FdrFooterContacts: React.FC<IFdrFooterContactsProps> = ({
  agency,
  defaultContactInfo,
}) => {
  const [t, { language }] = useTranslation(['common', 'agency']);
  const isDefaultAgency = agency.id === defaultAgencyId;

  const { query } = useRouter();

  const [isModalShowed, openModal, closeModal] = useToggleState(
    typeof query.appointment !== 'undefined'
  );

  const contactPageRouterProps = useMemo(() => {
    const route = getRouteByPageTypeLocale(EPageType.Contact, language);
    return {
      url: route.builder({}),
      type: FdrLinkType.Internal,
      openMethod: FdrLinkOpenMethod.ThisTab,
    };
  }, [language]);

  const phone = defaultContactInfo
    ? defaultContactInfo.phoneNumber
    : agency?.phone;

  const email = defaultContactInfo
    ? defaultContactInfo?.link?.url
    : agency?.email;

  return (
    <FooterContactsWrapper data-id="footer-contact">
      <FooterContactsTitle data-id="title">{agency?.name}</FooterContactsTitle>
      <PhoneLink
        href={`tel:${phone}`}
        data-id="phone-link"
        onClick={phoneClickHandler}
      >
        <PhoneIcon />
        <PhoneContent data-id="phone-content">
          <span className="-number" data-id="phone-number">
            {phone}
          </span>
        </PhoneContent>
      </PhoneLink>

      {isDefaultAgency ? (
        <ContactPageContainer>
          <ContactPageLink
            link={contactPageRouterProps}
            onClick={contactClickHandler}
          >
            <BsIcon name="mail" size="lg" />
            <span className="link-text">
              {t('common:footer_contact_box.contactPage')}
            </span>
          </ContactPageLink>
        </ContactPageContainer>
      ) : (
        <EmailLink
          href={`mailto:${email}`}
          data-id="email-link"
          onClick={emailClickHandler}
        >
          <EmailIcon />
          <EmailContent>
            <span className="-email" data-id="email-title">
              {agency?.email}
            </span>
          </EmailContent>
        </EmailLink>
      )}

      {isDefaultAgency ? (
        <ChatLink
          href={`${!defaultContactInfo ? 'mailto:' : ''}${email}`}
          data-id="chat-link"
          onClick={e => {
            e.preventDefault();
            expandChat();
            chatClickHandler();
          }}
        >
          <ChatIcon />
          <ChatContent>
            <span data-id="chat-title">
              {t('common:footer_contact_box.chat')}
            </span>
          </ChatContent>
          <FdrChat />
        </ChatLink>
      ) : (
        <ChatLink
          data-id="agency-appointment-link"
          onClick={() => {
            openModal();
            appointmentClickHandler();
          }}
        >
          <ChatIcon />
          <ChatContent>
            <span data-id="agency-appointment-title">
              {t('common:openingHours.signup')}
            </span>
          </ChatContent>
          {isModalShowed && agency?.appointmentLink?.url && (
            <FdrAgencyAppointment
              closeModal={closeModal}
              link={agency?.appointmentLink}
            />
          )}
        </ChatLink>
      )}
      <FdrSchedule
        className="schedule"
        testId={'opening-hours'}
        timezone={
          defaultContactInfo ? defaultContactInfo.timezone : agency.timezone
        }
        openingHours={
          defaultContactInfo
            ? defaultContactInfo.openingHours
            : agency.openingHours
        }
        mapCustomOpeningHoursToSchedules={mapOpeningHoursToSchedules}
        showStatus={false}
      />
    </FooterContactsWrapper>
  );
};

export default FdrFooterContacts;
