import styled from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FdrInfoCard } from '@hotelplan/fdr.regular.fusion.fdr-help-overlay';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const AgencyPhone = styled(BsLink)({
  '&:hover, &:focus': {
    textDecoration: 'underline',
    color: 'inherit',
  },
});

export const InfoCardWrapper = styled(FdrInfoCard)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    cursor: 'pointer',
    '.help-overlay-content-icon': {
      display: 'flex',
      gap: 4,
    },
    '.info-card-icon': {
      display: 'none',
    },
    '.help-overlay-contact-top': {
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      borderBottom: '1px solid',
      borderBottomColor: 'linkUnderlineColor',
      pb: 4,
    },
    '.help-overlay-contact-bottom': {
      '.additional-info': {
        variant: 'text.richText',
        ...FONT_SIZE.SMALL,
        p: { mb: 0 },
        'p:nth-of-type(2)': {
          mt: 3,
          color: 'darkGray',
        },
      },
    },
    '#olark-box-container': {
      display: 'none',
    },
  })
);

export const HelpOverlayContent = styled.div(
  ({ theme: { FONT_SIZE, colors } }) =>
    sx2CssThemeFn({
      p: '0',
      '.-image-text': {
        ...FONT_SIZE.LARGE,
        letterSpacing: '0.7px',
        textAlign: 'left',
        'a[href^="tel:"]': {
          color: colors.mainRed,
          textDecoration: 'none',
          textDecorationColor: colors.mainRed,
          '&:hover,&:focus': {
            color: colors.mainRed,
            textDecoration: 'underline',
          },
        },
        p: {
          mb: 2,
          '&:last-of-type': {
            mb: '0',
          },
        },
      },
      '> p.-image-text-secondary': {
        color: 'secondary',
        ...FONT_SIZE.SMALL,
      },
      '> p.-help-overlay-phone-title': {
        ...FONT_SIZE.LARGE,
        letterSpacing: '0.7px',
      },
      '> p.-image-text-phone-link': {
        ...FONT_SIZE.LARGE,
        color: 'mainRed',
      },
    })
);

export const HelpOverlayWrapper = styled(Container).attrs({
  'data-id': `help-overlay`,
})(({ theme }) => theme.helpOverlay.container);

export const HelpOverlayIcon = styled.div(
  ({ theme, theme: { media, space, colors } }) => ({
    marginTop: space[1],
    textAlign: 'center',
    alignContent: 'flex-end',
    fontSize: '0px',
    [media.tablet]: {
      marginTop: space[0],
    },
    '> .-icon-link': theme.link.iconLink,
    '.-icon-border': {
      transition: 'all 0.2s ease-in',
      color: 'white',
      backgroundColor: 'white',
      borderRadius: '50%',
      fontSize: '0px',
      display: 'flex',
      padding: '0px',
      justifyContent: 'center',
      alignItems: 'center',
      letterSpacing: '0',
      width: '48px',
      height: '48px',
      border: '2px solid',
      borderColor: colors.primary,
      '&:hover, &:focus, &:focus-visible': {
        backgroundColor: colors.lightMint,
        border: '2px solid',
        borderColor: colors.interactionPrimary,
        textDecoration: 'underline',
        outline: 'none',
      },
    },
    '.-icon-chevron-right': {
      width: '24px',
      height: '24px',
      color: colors.primary,
    },
  })
);

export const HelpOverlayTitle = styled.div(
  ({ theme: { FONT_SIZE } }) => FONT_SIZE.M
);

export const HelpOverlayClose = styled(BsButtonWithIcon).attrs({
  variant: E_BUTTON_TYPE.LINK_BUTTON,
})(({ theme }) => theme.helpOverlay.close);
