import React from 'react';
import { ClickEventType, trackClicks } from '@hotelplan/fdr.lib.track.meta';
import { expandChat } from '@hotelplan/fdr.regular.fusion.fdr-chat';
import { FdrAgencyFragment } from 'fdr/schemas/fragment/agency/fdr-agency.generated';
import { FdrHelpOverlayBoxItemFragment } from 'fdr/schemas/query/help-overlay/fdr-help-overlay.generated';
import { FdrHelpOverlayChatBox } from './fdr-help-overlay-chat-box';
import { FdrHelpOverlayGeneralBox } from './fdr-help-overlay-general-box';
import { FdrHelpOverlayPhoneBox } from './fdr-help-overlay-phone-box';
import { HelpOverlayBoxes } from './fdr-help-overlay.types';

interface IFdrHelpOverlayBoxesProps {
  boxes: FdrHelpOverlayBoxItemFragment[];
  boxTypeWhitelist?: HelpOverlayBoxes[];
  agency?: FdrAgencyFragment;
  onClickGeneralBox?(): void;
  noAgencyId: boolean;
}

export const FdrHelpOverlayBoxes: React.FC<IFdrHelpOverlayBoxesProps> = ({
  boxes,
  boxTypeWhitelist,
  agency,
  onClickGeneralBox,
  noAgencyId,
}) => {
  return (
    <>
      {(boxes || [])
        .map(fdrBox => {
          if (
            !Array.isArray(boxTypeWhitelist) ||
            boxTypeWhitelist.includes(fdrBox.__typename as HelpOverlayBoxes)
          )
            switch (fdrBox.__typename) {
              case HelpOverlayBoxes.BoxChat: {
                return (
                  <FdrHelpOverlayChatBox
                    key={fdrBox.id}
                    box={fdrBox}
                    boxesCount={boxes.length}
                    onClick={e => {
                      e.preventDefault();
                      expandChat();
                      trackClicks({
                        type: ClickEventType.CHAT,
                        payload: { source: 'help' },
                      });
                    }}
                  />
                );
              }
              case HelpOverlayBoxes.BoxContact: {
                return (
                  agency && (
                    <FdrHelpOverlayPhoneBox
                      key={fdrBox.id}
                      box={fdrBox}
                      boxesCount={boxes.length}
                      noAgencyId={noAgencyId}
                      onClick={() => {
                        trackClicks({
                          type: ClickEventType.CALL,
                          payload: { source: 'help' },
                        });
                      }}
                      agency={agency}
                    />
                  )
                );
              }
              case HelpOverlayBoxes.BoxGeneral: {
                return (
                  <FdrHelpOverlayGeneralBox
                    key={fdrBox.id}
                    box={fdrBox}
                    boxesCount={boxes.length}
                    onClick={() => {
                      trackClicks({
                        type: ClickEventType.BOX,
                        payload: { source: 'help' },
                      });
                      onClickGeneralBox?.();
                    }}
                  />
                );
              }
              default:
                return null;
            }
        })
        .filter(Boolean)}
    </>
  );
};
