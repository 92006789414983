import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  FdrFooterBrandLogoItem,
  FdrFooterPaymentMethodItem,
  FdrFooterSocialMediaItem,
} from '@hotelplan/supergraph-api';
import useFdrAgencyData from 'fdr/components/local/fdr-agency/useFdrAgencyData';
import { FdrFooterContactInformationFragment } from 'fdr/schemas/fragment/footer/fdr-footer-contact-information.generated';
import { useFdrFooterQuery } from 'fdr/schemas/query/footer/fdr-footer.generated';

const useFdrPageFooter = () => {
  const pageType = usePageType();

  const { data: fdrFooterData, loading: footerDataLoading } = useFdrFooterQuery(
    {
      notifyOnNetworkStatusChange: true,
      skip: !pageType,
    }
  );

  const {
    data: fdrAgencyData,
    loading: agencyLoading,
    noAgencyId,
  } = useFdrAgencyData();

  return {
    loading: footerDataLoading || agencyLoading,
    menus: fdrFooterData?.fdrFooter?.menus || [],
    socialMedia: (fdrFooterData?.fdrFooter?.socialMedias ||
      []) as FdrFooterSocialMediaItem[],
    paymentMethods: (fdrFooterData?.fdrFooter?.paymentMethods ||
      []) as FdrFooterPaymentMethodItem[],
    reasonsOfConfidence: {
      title: fdrFooterData?.fdrFooter?.reasonsOfConfidenceMainTitle || '',
      text: fdrFooterData?.fdrFooter?.reasonsOfConfidenceMainText || '',
    },
    agency: fdrAgencyData?.fdrAgency,
    brandLogos: (fdrFooterData?.fdrFooter?.brandLogos ||
      []) as FdrFooterBrandLogoItem[],
    defaultContactInfo: noAgencyId
      ? ((fdrFooterData?.fdrFooter?.contactInformation ||
          {}) as FdrFooterContactInformationFragment)
      : null,
  };
};

export default useFdrPageFooter;
