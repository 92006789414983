import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { mapOpeningHoursToSchedules } from '@hotelplan/fdr.lib.misc.schedule';
import { FdrHelpOverlayBox } from '@hotelplan/fdr.regular.fusion.fdr-help-overlay';
import { FdrSchedule } from '@hotelplan/fdr.regular.fusion.fdr-schedule';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrAgencyFragment } from 'fdr/schemas/fragment/agency/fdr-agency.generated';
import { FdrHelpOverlayBoxPhoneFragment } from 'fdr/schemas/query/help-overlay/fdr-help-overlay.generated';
import {
  AgencyPhone,
  HelpOverlayContent,
  HelpOverlayTitle,
  InfoCardWrapper,
} from './fdr-help-overlay.styles';

interface IFdrHelpOverlayPhoneBoxProps {
  box: FdrHelpOverlayBoxPhoneFragment;
  boxesCount: number;
  onClick?(e: React.MouseEvent): void;
  agency: FdrAgencyFragment;
  noAgencyId: boolean;
}

export function FdrHelpOverlayPhoneBox({
  box,
  boxesCount,
  onClick,
  agency,
  noAgencyId,
}: IFdrHelpOverlayPhoneBoxProps): ReactElement {
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();

  const isB2b = channelType === AuthChannelType.B2B;
  const phone = noAgencyId ? box.mainText : agency.phone;

  return (
    <FdrHelpOverlayBox
      boxesCount={boxesCount}
      link={box.targetUrl}
      onClick={onClick}
      className={`overlay-box box-call ${isB2b ? 'b2b' : 'b2c'}`}
    >
      <InfoCardWrapper className={`info-card`}>
        <div className="help-overlay-contact-top">
          <HelpOverlayTitle className="help-overlay-contact-title">
            <h4>{t('help.callUs')}</h4>
          </HelpOverlayTitle>
          <div className="help-overlay-content-icon">
            <HelpOverlayContent>
              {mobile && (
                <p className="-image-text-secondary">{agency.name + ':'}</p>
              )}
              {!mobile && agency.name && (
                <p
                  className="-help-overlay-phone-title"
                  data-id="help-overlay-phone-title"
                >
                  {agency.name + ':'}
                </p>
              )}
              {phone && (
                <p
                  className="-image-text-phone-link"
                  data-id="help-overlay-phone-link"
                >
                  <AgencyPhone href={`tel:${phone}`}>{phone}</AgencyPhone>
                </p>
              )}
            </HelpOverlayContent>
          </div>
          <FdrSchedule
            className="schedule"
            timezone={agency.timezone}
            openingHours={agency.openingHours}
            mapCustomOpeningHoursToSchedules={mapOpeningHoursToSchedules}
            showStatusIcon
          />
        </div>
        <div className="help-overlay-contact-bottom">
          <div
            className="additional-info"
            dangerouslySetInnerHTML={{
              __html: box.additionalInformation,
            }}
          />
        </div>
      </InfoCardWrapper>
    </FdrHelpOverlayBox>
  );
}
