import React from 'react';
import { useMetaModulesContext } from '@hotelplan/components.common.meta-modules';
import { FdrCookieDisclaimer } from '@hotelplan/fdr.regular.fusion.fdr-cookie-disclaimer';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useFdrCookieDisclaimerQuery } from 'fdr/schemas/query/cookie-disclaimer/fdr-cookie-disclaimer.generated';

const FdrCookieDisclaimerSection: React.FC = () => {
  const { hideCookieConsent } = useMetaModulesContext();
  const [isShowCookieDisclaimer, , hide] = useToggleState(!hideCookieConsent);

  const { data } = useFdrCookieDisclaimerQuery({
    skip: hideCookieConsent,
  });

  const cookieDisclaimerText = data?.fdrCookieDisclaimer?.mainText;

  return isShowCookieDisclaimer && cookieDisclaimerText ? (
    <FdrCookieDisclaimer text={cookieDisclaimerText} onAccept={hide} />
  ) : null;
};

export default FdrCookieDisclaimerSection;
