import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrOpeningHoursFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-opening-hours.generated';
import { FdrWebMetaFragmentDoc } from 'fdr/schemas/fragment/web-meta/fdr-web-meta.generated';
export type FdrAgencyFragment = {
  __typename?: 'FdrAgency';
  id: string;
  name: string;
  phone: string;
  email: string;
  brand: Types.FdrAgencyBrand;
  openStatus: Types.FdrAgencyOpenStatus;
  timezone: string;
  appointmentLink?: {
    __typename?: 'FdrLink';
    openMethod?: Types.FdrLinkOpenMethod | null;
    legacyUrl?: string | null;
    type: Types.FdrLinkType;
    url: string;
  } | null;
  openingHours?: {
    __typename?: 'FdrOpeningHours';
    monday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    tuesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    wednesday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    thursday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    friday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    saturday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
    sunday?: {
      __typename?: 'FdrWorkingDay';
      shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
    } | null;
  } | null;
  webMeta: {
    __typename?: 'FdrMeta';
    title: string;
    description: string;
    index: boolean;
    noFollow: boolean;
    sitemap: boolean;
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
};

export const FdrAgencyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAgency' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAgency' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrLink' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'openStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'openingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOpeningHours' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrWebMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
    ...FdrOpeningHoursFragmentDoc.definitions,
    ...FdrWebMetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
