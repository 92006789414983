export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    FdrBasePage: [
      'FdrNotFoundPage',
      'FdrAccessBookingDetailPage',
      'FdrThemeGeoPage',
      'FdrAccountBookingDetailsPage',
      'FdrAccountBookingsPage',
      'FdrAccountHistoryPage',
      'FdrAccountProfilePage',
      'FdrAgencyOverviewPage',
      'FdrNewsArticle',
      'FdrStaticPage',
      'FdrB2BLoginPage',
      'FdrBlogArticlesOverviewPage',
      'FdrBookmarksPage',
      'FdrCatalogPage',
      'FdrContactPage',
      'FdrRevokeEmailPermissionPage',
      'FdrRevokeEmailPermissionConfirmationPage',
      'FdrFlightHomePage',
      'FdrFlightSearchResultListPage',
      'FdrGeoOverviewPage',
      'FdrHolidayFinderLandingPage',
      'FdrHolidayFinderResultPage',
      'FdrHolidayFinderVotingPage',
      'FdrHomePage',
      'FdrNewsArticlesOverviewPage',
      'FdrNewsletterSubscriptionConfirmationPage',
      'FdrNewsletterSubscriptionFinalizationPage',
      'FdrNewsletterSubscriptionPage',
      'FdrNewsletterUnsubscriptionPage',
      'FdrNewsletterUnsubscriptionConfirmationPage',
      'FdrProductSearchResultListPage',
      'FdrThemeOverviewPage',
      'FdrWishlistPage',
    ],
    FdrMediaType: [
      'FdrImage',
      'FdrPdf',
      'FdrAudio',
      'FdrExternalMedia',
      'FdrVideo',
    ],
    FdrComponent: [
      'FdrSmartSeerRecommender',
      'FdrRssBlogArticleRecommender',
      'FdrAccordion',
      'FdrAccordionComponent',
      'FdrAgencyContactModule',
      'FdrAgencyRecommender',
      'FdrBenefitsComponent',
      'FdrBlogArticleRecommender',
      'FdrBrandsComponent',
      'FdrContactModule',
      'FdrEmployeesComponent',
      'FdrFaq',
      'FdrFlightOfferRecommender',
      'FdrFlightRecommender',
      'FdrGeoChildrenPlaceholder',
      'FdrGeoClimatePlaceholder',
      'FdrGeoGalleryPlaceholder',
      'FdrGeoLeadTextPlaceholder',
      'FdrGeoLongTextPlaceholder',
      'FdrGeoMapPlaceholder',
      'FdrGeoOfferButtonPlaceholder',
      'FdrGeoOfferRecommender',
      'FdrGeoRecommender',
      'FdrGeoRecommenderPlaceholder',
      'FdrHeroMediaGallery',
      'FdrImageText',
      'FdrLinkList',
      'FdrMarkdownText',
      'FdrMarketingRecommender',
      'FdrMarketingTeaser',
      'FdrMediaGallery',
      'FdrNewsArticlesRecommender',
      'FdrNewsletterSignup',
      'FdrProductOfferRecommender',
      'FdrProductOffersMap',
      'FdrProductRecommender',
      'FdrRawText',
      'FdrSrlButton',
      'FdrThemeGeoRecommender',
      'FdrThemeOfferRecommender',
      'FdrThemeRecommender',
      'FdrUspBoxesComponent',
    ],
    FdrAccountProduct: [
      'FdrCruise',
      'FdrHotel',
      'FdrProductInformation',
      'FdrRoundTrip',
    ],
    FdrProduct: ['FdrCruise', 'FdrHotel', 'FdrRoundTrip', 'FdrAdventureTravel'],
    FdrGeo: [
      'FdrDestination',
      'FdrCountry',
      'FdrContinent',
      'FdrCountryGroup',
      'FdrRegion',
      'FdrResort',
    ],
    FdrFlightSearchControls: [
      'FdrOneWayFlightSearchControls',
      'FdrOpenJawFlightSearchControls',
      'FdrReturnFlightSearchControls',
    ],
    FdrFlightSearchControlsFilter: [
      'FdrFlightSearchControlsMultiSelectFilter',
      'FdrFlightSearchControlsOneOptionFilter',
      'FdrFlightSearchControlsSingleSelectFilter',
    ],
    FdrSearchControlsFilter: [
      'FdrSearchControlsMultiSelectFilter',
      'FdrSearchControlsOneOptionFilter',
      'FdrSearchControlsSingleSelectFilter',
    ],
    FdrSearchControlsTravelDestination: [
      'FdrSearchControlsAirportDestination',
      'FdrSearchControlsGeoDestination',
      'FdrSearchControlsProductDestination',
      'FdrSearchControlsThemeDestination',
    ],
    FdrInPageNavigable: [
      'FdrSmartSeerRecommender',
      'FdrRssBlogArticleRecommender',
      'FdrAccordion',
      'FdrAccordionComponent',
      'FdrAgencyContactModule',
      'FdrAgencyRecommender',
      'FdrBenefitsComponent',
      'FdrBlogArticleRecommender',
      'FdrBrandsComponent',
      'FdrContactModule',
      'FdrEmployeesComponent',
      'FdrFaq',
      'FdrFlightOfferRecommender',
      'FdrFlightRecommender',
      'FdrGeoChildrenPlaceholder',
      'FdrGeoClimatePlaceholder',
      'FdrGeoGalleryPlaceholder',
      'FdrGeoLeadTextPlaceholder',
      'FdrGeoLongTextPlaceholder',
      'FdrGeoMapPlaceholder',
      'FdrGeoOfferButtonPlaceholder',
      'FdrGeoOfferRecommender',
      'FdrGeoRecommender',
      'FdrGeoRecommenderPlaceholder',
      'FdrHeroMediaGallery',
      'FdrImageText',
      'FdrLinkList',
      'FdrMarkdownText',
      'FdrMarketingRecommender',
      'FdrMarketingTeaser',
      'FdrMediaGallery',
      'FdrNewsArticlesRecommender',
      'FdrNewsletterSignup',
      'FdrProductOfferRecommender',
      'FdrProductOffersMap',
      'FdrProductRecommender',
      'FdrRawText',
      'FdrSrlButton',
      'FdrThemeGeoRecommender',
      'FdrThemeOfferRecommender',
      'FdrThemeRecommender',
      'FdrUspBoxesComponent',
    ],
    FdrLinkedComponent: [
      'FdrSmartSeerRecommender',
      'FdrRssBlogArticleRecommender',
      'FdrAgencyRecommender',
      'FdrBlogArticleRecommender',
      'FdrFlightOfferRecommender',
      'FdrFlightRecommender',
      'FdrGeoOfferRecommender',
      'FdrGeoRecommender',
      'FdrMarketingRecommender',
      'FdrProductOfferRecommender',
      'FdrProductRecommender',
      'FdrThemeGeoRecommender',
      'FdrThemeOfferRecommender',
      'FdrThemeRecommender',
    ],
    FdrGeoRatings: [
      'FdrDestinationNatureRatings',
      'FdrResortCultureRatings',
      'FdrResortEntertainmentRatings',
      'FdrResortFlairRatings',
      'FdrResortNatureRatings',
      'FdrResortSummerSportRatings',
      'FdrResortWaterSportRatings',
      'FdrResortWinterRatings',
    ],
    FdrAccountTransferPoint: [
      'FdrAccountFlightTransferPoint',
      'FdrAccountHotelTransferPoint',
    ],
    FdrAccountBookingDetails: [
      'FdrAccountBooking',
      'FdrAccountBookingListItem',
    ],
    FdrPaginatedQueryResponse: [
      'FdrAllBookmarkGroupsResponse',
      'FdrSearchHistoryResponse',
      'FdrVisitHistoryResponse',
    ],
    FdrBookmarkedObject: [
      'FdrBookmarkedGeo',
      'FdrBookmarkedProduct',
      'FdrBookmarkedTheme',
    ],
    FdrBookmarkItem: [
      'FdrGeoBookmark',
      'FdrProductBookmark',
      'FdrThemeBookmark',
    ],
    FdrQueryResponse: ['FdrBookmarksCountResponse'],
    FdrGeoOrProductSearchResult: [
      'FdrGeoSearchResult',
      'FdrProductSearchResult',
    ],
    FdrHelpOverlayBoxItem: [
      'FdrHelpOverlayBox',
      'FdrHelpOverlayBoxChat',
      'FdrHelpOverlayBoxPhone',
    ],
    FdrLastActivityItem: [
      'FdrLastActivityOffer',
      'FdrLastActivitySearch',
      'FdrLastActivityWishlistProduct',
    ],
    FdrAgencySuggestion: ['FdrAgency', 'FdrAgencyGeoLocation'],
    FdrSearchHistory: [
      'FdrFlightSearchHistory',
      'FdrProductSearchHistory',
      'FdrTextSearchHistory',
    ],
    FdrSearchItem: [
      'FdrAdventureTravelSearchItem',
      'FdrAgencyOverviewPageSearchItem',
      'FdrAirportSearchItem',
      'FdrBlogArticleSearchItem',
      'FdrCatalogPageSearchItem',
      'FdrContinentSearchItem',
      'FdrCountryGroupSearchItem',
      'FdrCountrySearchItem',
      'FdrCruiseSearchItem',
      'FdrDestinationSearchItem',
      'FdrHotelSearchItem',
      'FdrNewsletterSubscriptionPageSearchItem',
      'FdrRegionSearchItem',
      'FdrResortSearchItem',
      'FdrRoundTripSearchItem',
      'FdrStaticPageSearchItem',
      'FdrThemeSearchItem',
    ],
    FdrHistoryVisitedObject: [
      'FdrHistoryVisitedAgency',
      'FdrHistoryVisitedGeo',
      'FdrHistoryVisitedProduct',
      'FdrHistoryVisitedStaticPage',
      'FdrHistoryVisitedTheme',
    ],
    FdrMutationResponse: [
      'FdrAccountAddOnlineBookingResponse',
      'FdrAccountAddTravelCompanionResponse',
      'FdrAccountAddUpsellTransferResponse',
      'FdrAccountCancellationResult',
      'FdrAccountChangePaymentMethodResponse',
      'FdrAccountDeleteTravelCompanionResponse',
      'FdrAccountDeleteUpsellTransferServiceResponse',
      'FdrAccountFinalizeBookingCancellationResponse',
      'FdrAccountInitCancellationResult',
      'FdrAccountInitPaymentResponse',
      'FdrAccountResendTravelDocumentResponse',
      'FdrAccountUpdateEmergencyPhoneResponse',
      'FdrAccountUpdatePersonalDataResponse',
      'FdrAccountUpdateTravelCompanionResponse',
      'FdrAddBookmarkResponse',
      'FdrAddBookmarksResponse',
      'FdrAddSearchHistoryResponse',
      'FdrAddSharedWishlistResponse',
      'FdrAddVisitHistoryResponse',
      'FdrAddWishlistItemToActiveResponse',
      'FdrCopyBookmarkResponse',
      'FdrCreateBookmarkGroupResponse',
      'FdrCreateWishlistResponse',
      'FdrDeleteBookmarkResponse',
      'FdrDeleteBookmarkGroupResponse',
      'FdrDeleteBookmarksResponse',
      'FdrDeleteSearchHistoryResponse',
      'FdrDeleteVisitHistoryResponse',
      'FdrDeleteWishlistResponse',
      'FdrDeleteWishlistItemFromActiveResponse',
      'FdrMergeAnonymousSearchHistoryResponse',
      'FdrMergeAnonymousWishlistResponse',
      'FdrMoveBookmarkResponse',
      'FdrMoveWishlistItemToWishlistResponse',
      'FdrRenameBookmarkGroupResponse',
      'FdrRenameWishlistResponse',
      'FdrSetActiveWishlistResponse',
    ],
    FdrContactModuleItem: ['FdrContactModuleLink', 'FdrContactModulePhone'],
    FdrMedia: [
      'FdrAudio',
      'FdrExternalMedia',
      'FdrImage',
      'FdrPdf',
      'FdrVideo',
    ],
    FdrFlightSearchControlsFilterOption: [
      'FdrSearchControlsAirlineOption',
      'FdrSearchControlsDepartureFlightArrivalTimeOption',
      'FdrSearchControlsDepartureFlightDepartureTimeOption',
      'FdrSearchControlsFlightStopoverDurationOption',
      'FdrSearchControlsMaxPriceOption',
      'FdrSearchControlsMaxStopsOption',
      'FdrSearchControlsMinPriceOption',
      'FdrSearchControlsReturnFlightArrivalTimeOption',
      'FdrSearchControlsReturnFlightDepartureTimeOption',
    ],
    FdrSearchControlsFilterOption: [
      'FdrSearchControlsAccommodationSizeOption',
      'FdrSearchControlsAirlineOption',
      'FdrSearchControlsDepartureAirportOption',
      'FdrSearchControlsDepartureFlightDepartureTimeOption',
      'FdrSearchControlsFlightDurationOption',
      'FdrSearchControlsFlightProviderOption',
      'FdrSearchControlsHpRatingOption',
      'FdrSearchControlsMaxPriceOption',
      'FdrSearchControlsMaxStopsOption',
      'FdrSearchControlsMealTypeOption',
      'FdrSearchControlsMinPriceOption',
      'FdrSearchControlsProductCodeOption',
      'FdrSearchControlsProductFeatureOption',
      'FdrSearchControlsProductProviderOption',
      'FdrSearchControlsReturnFlightDepartureTimeOption',
      'FdrSearchControlsRoomTypeOption',
      'FdrSearchControlsTaRatingOption',
    ],
  },
};
export default result;
