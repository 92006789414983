import styled from 'styled-components';
import { WhiteBoxModal } from '@hotelplan/components.common.modals';

export const AgencyContactFormModal = styled(WhiteBoxModal)(({ theme }) => ({
  '.modal-wrapper': theme.modals.wideModalWrapper,
  '.modal-body': theme.modals.commonModalBody,

  '&.iframed': {
    '.shadow-overlay-children .modal-wrapper': {
      height: '100%',
      iframe: {
        height: '100%',
        border: 0,
      },
    },
    '.shadow-overlay-children .modal-body': {
      padding: 0,
      '.form-all': {
        marginTop: 0,
      },
    },
  },
}));
