import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrFooterHeading } from '@hotelplan/fdr.regular.fusion.fdr-footer';
import { sx2CssThemeFn, SxStyleProp } from '@hotelplan/util.theme.sxc';

const linkCss: SxStyleProp = {
  display: 'flex',
  marginBottom: [2, 3],
  fontSize: [2, 3],
  lineHeight: ['24px', '30px'],
  '.icon': {
    margin: 'auto',
  },
};

const linkIconCss: SxStyleProp = {
  display: 'flex',
  marginRight: 2,
};

const linkTextCss: SxStyleProp = {
  flex: 1,
  borderLeft: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: ['column', 'row'],
  justifyContent: ['center', 'flex-start'],
  alignItems: 'start',
  px: [3, 0],
};

export const FooterContactsWrapper = styled.div(
  ({ theme: { space, colors, media, fontSizes } }) => ({
    marginTop: space[3],
    padding: space[4],
    backgroundColor: colors.background,
    flexGrow: 1,
    [media.tablet]: {
      marginTop: 0,
      flexBasis: '50%',
    },
    a: {
      textDecoration: 'underline',
      textDecorationColor: colors.linkUnderlineColor,
      textUnderlineOffset: '6px',
      color: colors.defaultText,
      transition: 'none',
      transform: 'translate(0)',
      '&:hover,&:focus': {
        color: colors.defaultText,
        textDecorationColor: colors.defaultText,
        transform: 'scale(1)',
      },
    },
    '.schedule': {
      [media.mobile]: {
        fontSize: fontSizes[1],
        lineHeight: '20px',
      },
    },
  })
);

export const FooterContactsTitle = styled(FdrFooterHeading)(
  sx2CssThemeFn({
    display: 'block',
    textAlign: 'start',
    marginBottom: 4,
  })
);

export const PhoneLink = styled(BsLink)(
  sx2CssThemeFn(linkCss, {
    marginBottom: 3,
    lineHeight: ['24px', '30px'],
  })
);

export const PhoneIcon = styled.span.attrs({
  children: <BsIcon name="phone" size="lg" />,
})(sx2CssThemeFn(linkIconCss));

export const PhoneContent = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(linkTextCss, {
    paddingLeft: 0,
    '.-number': FONT_SIZE.LARGE,
  })
);

export const EmailLink = styled(BsLink)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(linkCss, {
    marginBottom: 3,
    '.-email': FONT_SIZE.LARGE,
  })
);

export const EmailContent = styled.div(
  sx2CssThemeFn(linkTextCss, {
    paddingLeft: 0,
  })
);

export const EmailIcon = styled.span.attrs({
  children: <BsIcon name="mail" size="lg" />,
})(sx2CssThemeFn(linkIconCss));

export const ChatLink = styled(BsLink)(
  sx2CssThemeFn(linkCss, {
    marginBottom: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  })
);

export const ChatContent = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(linkTextCss, {
    ...FONT_SIZE.LARGE,
    paddingLeft: 0,
  })
);

export const ChatIcon = styled.span.attrs({
  children: <BsIcon name="chat" size="lg" />,
})(sx2CssThemeFn(linkIconCss));

export const ContactPageContainer = styled.div(
  sx2CssThemeFn({
    a: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 3,
      fontSize: [1, '18px'],
      lineHeight: ['24px', '28px'],
    },
  })
);

export const ContactPageLink = styled(FdrRoute).attrs({
  'data-id': `contact-page-link`,
})(({ theme: { space, colors, fontSizes, media } }) => ({
  '.link-text': {
    fontSize: fontSizes[2],
    lineHeight: '24px',
    paddingLeft: 0,
    [media.tablet]: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  '.icon': {
    fill: colors.black,
    marginRight: space[2],
  },
}));
