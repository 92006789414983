import noop from 'lodash/noop';
import React, { useContext } from 'react';
import { useToggleState } from '@hotelplan/libs.hooks-react';

type TSetHandler = () => void;

interface IToggleStateContextState {
  value: boolean;
  enable: TSetHandler;
  disable: TSetHandler;
  toggle: TSetHandler;
}

const FdrHelpOverlayToggleStateContext =
  React.createContext<IToggleStateContextState>({
    value: undefined,
    enable: noop,
    disable: noop,
    toggle: noop,
  });

export const HelpOverlayToggleStateContextProvider: React.FC<{
  children?: React.ReactNode;
  defaultValue: boolean;
}> = ({ children, defaultValue }) => {
  const [value, enable, disable, toggle] = useToggleState(defaultValue);

  return (
    <FdrHelpOverlayToggleStateContext.Provider
      value={{ value, enable, disable, toggle }}
    >
      {children}
    </FdrHelpOverlayToggleStateContext.Provider>
  );
};

export function useHelpOverlayToggleStateContext() {
  return useContext(FdrHelpOverlayToggleStateContext);
}
