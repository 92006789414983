import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { getFdrRouterLink } from '@hotelplan/fdr.lib.router.target-page';
import { FdrHelpOverlayBox } from '@hotelplan/fdr.regular.fusion.fdr-help-overlay';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrHelpOverlayBoxFragment } from 'fdr/schemas/query/help-overlay/fdr-help-overlay.generated';
import {
  HelpOverlayContent,
  HelpOverlayIcon,
  HelpOverlayTitle,
  InfoCardWrapper,
} from './fdr-help-overlay.styles';

interface IFdrHelpOverlayGeneralBoxProps {
  box: FdrHelpOverlayBoxFragment;
  boxesCount: number;
  onClick?(e: React.MouseEvent): void;
}

export function FdrHelpOverlayGeneralBox({
  box,
  boxesCount,
  onClick,
}: IFdrHelpOverlayGeneralBoxProps): ReactElement {
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();

  const isB2b = channelType === AuthChannelType.B2B;

  return (
    <FdrHelpOverlayBox
      boxesCount={boxesCount}
      link={box.targetUrl}
      onClick={onClick}
      className={`overlay-box box-default ${isB2b ? 'b2b' : 'b2c'}`}
    >
      <InfoCardWrapper className={`info-card`}>
        <HelpOverlayTitle>
          <h4>{box.mainTitle}</h4>
        </HelpOverlayTitle>
        <div className="help-overlay-content-icon">
          <HelpOverlayContent>
            {!mobile && box.mainText && (
              <div
                className="-image-text"
                dangerouslySetInnerHTML={{ __html: box.mainText }}
              />
            )}
          </HelpOverlayContent>
          {box?.targetUrl && getFdrRouterLink(box?.targetUrl) ? (
            <HelpOverlayIcon className="help-overlay-icon">
              <div className="-icon-link">
                <div className="-icon-border">
                  <BsIcon
                    name="chevron-right"
                    className="-icon-chevron-right"
                  />
                </div>
              </div>
            </HelpOverlayIcon>
          ) : null}
        </div>
      </InfoCardWrapper>
    </FdrHelpOverlayBox>
  );
}
