import getConfig from 'next/config';
import { useRequestContextHeaders } from '@hotelplan/fdr.lib.context.request';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useFdrAccountCustomerAgencyQuery } from 'fdr/schemas/query/account/fdr-account-customer-agency.generated';
import { useFdrAgencyQuery } from 'fdr/schemas/query/agency/fdr-agency.generated';

const { defaultAgencyId } = getConfig().publicRuntimeConfig;

const useFdrAgencyData = () => {
  const { channelType } = useAuthentication();
  const withAccountAgency = channelType === AuthChannelType.B2C;
  const { agency } = useRequestContextHeaders();

  const { data: fdrAccount, loading: accountLoading } =
    useFdrAccountCustomerAgencyQuery({
      skip: !withAccountAgency,
    });

  const agencyId =
    agency ||
    fdrAccount?.fdrAccount?.profile?.personalData?.accountCustomer
      ?.agencyInfo ||
    defaultAgencyId;

  const { data: fdrAgencyData, loading: agencyContactLoading } =
    useFdrAgencyQuery({
      variables: { input: { id: agencyId } },
      skip: withAccountAgency && accountLoading,
    });

  return {
    data: fdrAgencyData,
    loading: accountLoading || agencyContactLoading,
    noAgencyId: !Boolean(
      agency ||
        fdrAccount?.fdrAccount?.profile?.personalData?.accountCustomer
          ?.agencyInfo ||
        ''
    ),
  };
};

export default useFdrAgencyData;
