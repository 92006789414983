import React from 'react';
import { FdrInformationNotification } from '@hotelplan/fdr.regular.fusion.fdr-information-notification';
import { useFdrInformationNotificationQuery } from 'fdr/schemas/query/notifications/fdr-information-notification.generated';

const FdrInformationNotificationSection = () => {
  const { data } = useFdrInformationNotificationQuery();

  const notification =
    (data?.fdrNotifications?.information || []).at(0) || null;

  return (
    notification && (
      <FdrInformationNotification
        text={notification?.mainText}
        image={{ image: notification?.icon }}
      />
    )
  );
};

export default React.memo(FdrInformationNotificationSection);
