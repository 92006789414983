import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrFooterFragmentDoc } from 'fdr/schemas/fragment/footer/fdr-footer.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrFooterQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FdrFooterQuery = {
  __typename?: 'Query';
  fdrFooter?: {
    __typename?: 'FdrFooter';
    reasonsOfConfidenceMainTitle?: string | null;
    reasonsOfConfidenceMainText?: string | null;
    socialMedias?: Array<{
      __typename?: 'FdrFooterSocialMediaItem';
      icon: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      };
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }> | null;
    menus: Array<{
      __typename?: 'FdrMenu';
      caption: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
      menuItems?: Array<{
        __typename?: 'FdrMenuItem';
        caption: string;
        iconType?: Types.FdrMenuIconType | null;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }> | null;
    }>;
    paymentMethods?: Array<{
      __typename?: 'FdrFooterPaymentMethodItem';
      link?: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      } | null;
      icon: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      };
    }> | null;
    brandLogos?: Array<{
      __typename?: 'FdrFooterBrandLogoItem';
      link?: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      } | null;
      icon: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      };
    }> | null;
    logo?: {
      __typename?: 'FdrImage';
      alt: string;
      description?: string | null;
      copyright?: string | null;
      rawUrl: string;
      desaturated?: boolean | null;
      resized: Array<{
        __typename?: 'FdrResizedImage';
        id: string;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }>;
    } | null;
    contactInformation?: {
      __typename?: 'FdrContactInformation';
      timezone: string;
      phoneNumber: string;
      mainTitle?: string | null;
      openingHours?: {
        __typename?: 'FdrOpeningHours';
        monday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        tuesday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        wednesday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        thursday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        friday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        saturday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
        sunday?: {
          __typename?: 'FdrWorkingDay';
          shifts?: Array<{
            __typename?: 'FdrShift';
            from: any;
            to: any;
          }> | null;
        } | null;
      } | null;
      link?: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      } | null;
    } | null;
  } | null;
};

export const FdrFooterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrFooter' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrFooter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFooter' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFooterFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrFooterQuery__
 *
 * To run a query within a React component, call `useFdrFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrFooterQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrFooterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrFooterQuery,
    FdrFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FdrFooterQuery, FdrFooterQueryVariables>(
    FdrFooterDocument,
    options
  );
}
export function useFdrFooterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrFooterQuery,
    FdrFooterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FdrFooterQuery, FdrFooterQueryVariables>(
    FdrFooterDocument,
    options
  );
}
export type FdrFooterQueryHookResult = ReturnType<typeof useFdrFooterQuery>;
export type FdrFooterLazyQueryHookResult = ReturnType<
  typeof useFdrFooterLazyQuery
>;
export type FdrFooterQueryResult = Apollo.QueryResult<
  FdrFooterQuery,
  FdrFooterQueryVariables
>;
