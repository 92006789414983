import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrWorkingDayFragment = {
  __typename?: 'FdrWorkingDay';
  shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
};

export const FdrWorkingDayFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrWorkingDay' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrWorkingDay' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shifts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
