import { FieldMergeFunction } from '@apollo/client';
import {
  fdrMergePage,
  fdrMergePaginatedItems,
} from '@hotelplan/fdr.lib.apollo.policies';
import {
  FdrAccountBookingListItem,
  FdrAccountBookingListResponse,
  FdrSearchHistory,
  FdrSearchHistoryResponse,
  FdrSearchItem,
  FdrTextAutocompleteResponse,
  FdrVisitHistory,
  FdrVisitHistoryResponse,
} from '@hotelplan/supergraph-api';
import { getNotNullObjectFieldFilter } from 'fdr/lib/filter';

export const fdrSearchHistoryMerge: FieldMergeFunction<FdrSearchHistoryResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const mergedItems = fdrMergePaginatedItems<FdrSearchHistory>(
      incoming.pagination,
      undefined,
      existing.entries,
      incoming.entries
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      mergedItems.length
    );

    return {
      ...incoming,
      entries: mergedItems,
      pagination: mergedPagination,
    };
  };

export const fdrVisitHistoryMerge: FieldMergeFunction<FdrVisitHistoryResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const mergedItems = fdrMergePaginatedItems<FdrVisitHistory>(
      incoming.pagination,
      undefined,
      existing.entries,
      incoming.entries
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      mergedItems.length
    );

    return {
      ...incoming,
      entries: mergedItems,
      pagination: mergedPagination,
    };
  };

export const fdrAutocompleteMerge: FieldMergeFunction<FdrTextAutocompleteResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const mergedItems = fdrMergePaginatedItems<FdrSearchItem>(
      incoming.pagination,
      undefined,
      existing.itemList,
      incoming.itemList
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      mergedItems.length
    );

    return {
      ...incoming,
      itemList: mergedItems,
      pagination: mergedPagination,
    };
  };

export const fdrBookingsMerge: FieldMergeFunction<FdrAccountBookingListResponse> =
  (existing, incoming) => {
    if (!existing) return incoming;

    const mergedBookingList = fdrMergePaginatedItems<FdrAccountBookingListItem>(
      incoming.pagination,
      undefined,
      existing.bookingList,
      incoming.bookingList
    );

    const mergedPagination = fdrMergePage(
      existing.pagination,
      incoming.pagination,
      mergedBookingList.length
    );

    return {
      ...incoming,
      bookingList: mergedBookingList,
      pagination: mergedPagination,
    };
  };

export const createFilterInactiveMerge =
  <TItem extends object>(
    fieldAccessor: (obj: TItem) => any
  ): FieldMergeFunction<TItem[]> =>
  (existing, incoming) =>
    incoming?.filter(getNotNullObjectFieldFilter(fieldAccessor)) ?? incoming;
