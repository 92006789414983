import React from 'react';
import { FdrMaintenanceNotifications } from '@hotelplan/fdr.regular.fusion.fdr-maintenance-notifications';
import { useFdrMaintenanceNotificationQuery } from 'fdr/schemas/query/notifications/fdr-maintenance-notification.generated';

const FdrMaintenanceNotificationsSection = () => {
  const { data } = useFdrMaintenanceNotificationQuery();

  return (
    <FdrMaintenanceNotifications
      notifications={data?.fdrNotifications?.maintenance || []}
    />
  );
};

export default FdrMaintenanceNotificationsSection;
