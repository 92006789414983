import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { track } from '@hotelplan/libs.tracking-events';
import {
  FeedbackBoxModal,
  FooterFeedbackBoxWrapper,
  FormLink,
  ResponsiveIFrame,
  Title,
} from './fdr-footer-feedback.styles';

const trackOpenFeedbackClick = () => {
  track({
    event: `🚀 click.feedback`,
    eventMetadata: {
      source: `footer`,
    },
    _clear: true,
  });
};

const FdrFooterFeedback = () => {
  const { t } = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FooterFeedbackBoxWrapper>
      <Title>{t('common:footer_feedback_box.title')}</Title>
      <FormLink
        onClick={() => {
          trackOpenFeedbackClick();
          setIsModalOpen(true);
        }}
      >
        {t('common:footer_feedback_box.linkName')}
      </FormLink>
      <FeedbackBoxModal
        show={isModalOpen}
        title=""
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <ResponsiveIFrame
          title={`footer_feedback_box`}
          src={t('common:footer_feedback_box.link')}
          width="100%"
        />
      </FeedbackBoxModal>
    </FooterFeedbackBoxWrapper>
  );
};

export default FdrFooterFeedback;
