import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrAccountPhoneFragmentDoc } from './fdr-account-phone.generated';
export type FdrAccountCustomerFragment = {
  __typename?: 'FdrAccountCustomer';
  salutation?: Types.FdrSalutation | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  birthdate?: any | null;
  nationality?: string | null;
  agencyInfo?: string | null;
  addressDetails?: {
    __typename?: 'FdrAccountAddressDetails';
    street?: string | null;
    zip?: string | null;
    city?: string | null;
    country?: string | null;
  } | null;
  phone?: {
    __typename?: 'FdrAccountPhone';
    country?: string | null;
    phone?: string | null;
  } | null;
  mobilePhone?: {
    __typename?: 'FdrAccountPhone';
    country?: string | null;
    phone?: string | null;
  } | null;
};

export const FdrAccountCustomerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAccountCustomer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAccountCustomer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAccountPhone' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mobilePhone' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAccountPhone' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agencyInfo' } },
        ],
      },
    },
    ...FdrAccountPhoneFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
