import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrMaintenanceNotificationFragmentDoc } from 'fdr/schemas/fragment/notifications/fdr-maintenance-notification.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrMaintenanceNotificationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrMaintenanceNotificationQuery = {
  __typename?: 'Query';
  fdrNotifications: {
    __typename?: 'FdrNotificationsResponse';
    maintenance?: Array<{
      __typename?: 'FdrMaintenanceNotification';
      id: string;
      mainTitle: string;
      mainText: string;
      backgroundColor: string;
      closable?: boolean | null;
      notificationType: Types.FdrMaintenanceNotificationType;
      showIfClosed?: Types.FdrMaintenanceNotificationShowMode | null;
    }> | null;
  };
};

export const FdrMaintenanceNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrMaintenanceNotification' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maintenance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrMaintenanceNotification',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrMaintenanceNotificationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrMaintenanceNotificationQuery__
 *
 * To run a query within a React component, call `useFdrMaintenanceNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrMaintenanceNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrMaintenanceNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrMaintenanceNotificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrMaintenanceNotificationQuery,
    FdrMaintenanceNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrMaintenanceNotificationQuery,
    FdrMaintenanceNotificationQueryVariables
  >(FdrMaintenanceNotificationDocument, options);
}
export function useFdrMaintenanceNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrMaintenanceNotificationQuery,
    FdrMaintenanceNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrMaintenanceNotificationQuery,
    FdrMaintenanceNotificationQueryVariables
  >(FdrMaintenanceNotificationDocument, options);
}
export type FdrMaintenanceNotificationQueryHookResult = ReturnType<
  typeof useFdrMaintenanceNotificationQuery
>;
export type FdrMaintenanceNotificationLazyQueryHookResult = ReturnType<
  typeof useFdrMaintenanceNotificationLazyQuery
>;
export type FdrMaintenanceNotificationQueryResult = Apollo.QueryResult<
  FdrMaintenanceNotificationQuery,
  FdrMaintenanceNotificationQueryVariables
>;
