import {
  registerRouteConfig,
  registerPageRoutes,
} from '@hotelplan/libs.router-config';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';

const routes = registerPageRoutes([
  {
    page: '/',
    pageType: EPageType.Home,
    match: '/',
    locale: 'de',
    prefix: false,
  },
  {
    page: '/',
    pageType: EPageType.Home,
    match: '/',
    locale: 'fr',
    prefix: true,
  },
]);

export default routes;

[
  [
    `/holidayfinder`,
    `/holidayfinder`,
    `/holiday-finder`,
    EPageType.HolidayFinderLanding,
  ],
  [
    `/reisebuero-filiale`,
    `/agence-de-voyage`,
    `/agency-overview`,
    EPageType.AgencyOverview,
  ],
  [
    `/reisebuero-filiale/:slug/:id`,
    `/agence-de-voyage/:slug/:id`,
    `/agency`,
    EPageType.AgencyObject,
  ],
  [`/b2b/login`, `/b2b/login`, `/b2b-login`, EPageType.B2BLogin],
  [`/kataloge`, `/catalogues`, `/catalogs`, EPageType.CatalogOverview],
  [`/kontakt`, `/contact`, `/contact`, EPageType.Contact],
  [
    `/email-permission/widerrufen`,
    `/email-permission/retracter`,
    `/email-revoke`,
    EPageType.EmailAdvertisingRevocation,
  ],
  [
    `/email-permission/widerruf-erfolgreich`,
    `/email-permission/retractation-succes`,
    `/email-revoke-success`,
    EPageType.EmailAdvertisingRevocationConfirmation,
  ],
  [`/flug`, `/vol`, `/flight`, EPageType.FlightHome],
  [`/flug/suchen`, `/vol/chercher`, `/flight-search`, EPageType.FlightSrl],
  [
    `/ferien/:params/:id([kgcrdf]-\\d+)`,
    `/vacances/:params/:id([kgcrdf]-\\d+)`,
    `/geo`,
    EPageType.GeoObject,
  ],
  [
    `/ferien/reiseziele`,
    `/vacances/destinations`,
    `/geo-overview`,
    EPageType.GeoOverview,
  ],
  [`/konto`, `/compte`, `/my-account`, EPageType.MyaccountOverview],
  [
    `/konto/meine-reisen`,
    `/compte/mes-voyages`,
    `/my-account-bookings`,
    EPageType.MyaccountMybookings,
  ],
  [
    `/konto/meine-reisen/buchung/:id`,
    `/compte/mes-voyages/reservation/:id`,
    `/my-account-booking-details`,
    EPageType.MyaccountBookingDetails,
  ],
  [
    `/konto/meine-suchen`,
    `/compte/mon-historique`,
    `/my-account-history`,
    EPageType.MyaccountMyhistory,
  ],
  [
    `/konto/mein-konto`,
    `/compte/mon-compte`,
    `/my-account-profile`,
    EPageType.MyaccountMyprofile,
  ],
  [
    `/newsletter/anmeldung`,
    `/newsletter/abonnement`,
    `/newsletter-subscribe`,
    EPageType.NewsletterSubscription,
  ],
  [
    `/newsletter/anmeldung-bestaetigen`,
    `/newsletter/abonnement-confirmer`,
    `/newsletter-subscribe-confirm`,
    EPageType.NewsletterConfirmation,
  ],
  [
    `/newsletter/anmeldung-erfolgreich`,
    `/newsletter/abonnement-succes`,
    `/newsletter-subscribe-success`,
    EPageType.NewsletterFinalization,
  ],
  [
    `/newsletter/abmeldung`,
    `/newsletter/desabonnement`,
    `/newsletter-unsubscribe`,
    EPageType.NewsletterUnsubscription,
  ],
  [
    `/newsletter/abmeldung-erfolgreich`,
    `/newsletter/desabonnement-succes`,
    `/newsletter-unsubscribe-success'`,
    EPageType.NewsletterUnsubscriptionFinalization,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)`,
    `/vacances/:destination/:params/:id([hos]-\\d+)`,
    `/pdp-description`,
    EPageType.PdpDescription,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)/region-klima`,
    `/vacances/:destination/:params/:id([hos]-\\d+)/region-climat`,
    `/pdp-destination`,
    EPageType.PdpRegion,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)/preise`,
    `/vacances/:destination/:params/:id([hos]-\\d+)/prix`,
    `/pdp-orl`,
    EPageType.Orl,
  ],
  [
    `/ferien/:destination/:params/:id([hos]-\\d+)/bewertungen`,
    `/vacances/:destination/:params/:id([hos]-\\d+)/evaluations`,
    `/pdp-reviews`,
    EPageType.PdpReviews,
  ],
  [`/ferien/suchen`, `/vacances/chercher`, `/search`, EPageType.Srl],
  [
    `/ferien/:params/:id(t-\\d+)`,
    `/vacances/:params/:id(t-\\d+)`,
    `/theme`,
    EPageType.ThemeObject,
  ],
  [
    `/ferien/reiseideen`,
    `/vacances/idees-de-voyage`,
    `/theme-overview`,
    EPageType.ThemeOverview,
  ],
  [`/merkliste`, `/liste-de-favoris`, `/wishlist-list`, EPageType.Wishlist],
  [
    `/merkliste/teilen/:id`,
    `/liste-de-favoris/partager/:id`,
    `/wishlist-share`,
    EPageType.Wishlist,
  ],
  [
    `/merkliste/listenansicht`,
    `/liste-de-favoris/vue-liste`,
    `/wishlist-list`,
    EPageType.WishlistList,
  ],
  [
    `/merkliste/vergleichen`,
    `/liste-de-favoris/comparer`,
    `/wishlist-compare`,
    EPageType.WishlistCompare,
  ],
  [`/:path*`, `/:path*`, `/[...slug]`, EPageType.Static, false, true],
].forEach(([de, fr, page, pageType, prefix, fallback]) => {
  const entries = Object.entries({ de, fr });

  entries.forEach(([locale, match]) => {
    routes.push(
      registerRouteConfig({
        page,
        pageType,
        match,
        locale,
        prefix,
        fallback,
      })
    );
  });
});
