import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrInformationNotificationFragmentDoc } from 'fdr/schemas/fragment/notifications/fdr-information-notification.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrInformationNotificationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrInformationNotificationQuery = {
  __typename?: 'Query';
  fdrNotifications: {
    __typename?: 'FdrNotificationsResponse';
    information?: Array<{
      __typename?: 'FdrInfoNotification';
      mainText: string;
      icon?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
    }> | null;
  };
};

export const FdrInformationNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrInformationNotification' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'information' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrInformationNotification',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrInformationNotificationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrInformationNotificationQuery__
 *
 * To run a query within a React component, call `useFdrInformationNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrInformationNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrInformationNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrInformationNotificationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrInformationNotificationQuery,
    FdrInformationNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrInformationNotificationQuery,
    FdrInformationNotificationQueryVariables
  >(FdrInformationNotificationDocument, options);
}
export function useFdrInformationNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrInformationNotificationQuery,
    FdrInformationNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrInformationNotificationQuery,
    FdrInformationNotificationQueryVariables
  >(FdrInformationNotificationDocument, options);
}
export type FdrInformationNotificationQueryHookResult = ReturnType<
  typeof useFdrInformationNotificationQuery
>;
export type FdrInformationNotificationLazyQueryHookResult = ReturnType<
  typeof useFdrInformationNotificationLazyQuery
>;
export type FdrInformationNotificationQueryResult = Apollo.QueryResult<
  FdrInformationNotificationQuery,
  FdrInformationNotificationQueryVariables
>;
