export enum HelpOverlayEvent {
  Open = 'help-ov-open',
  Close = 'help-ov-close',
}

export enum HelpOverlayBoxes {
  BoxContact = 'FdrHelpOverlayBoxPhone',
  BoxChat = 'FdrHelpOverlayBoxChat',
  BoxGeneral = 'FdrHelpOverlayBox',
}
