import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrAgencyFragmentDoc } from 'fdr/schemas/fragment/agency/fdr-agency.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAgencyQueryVariables = Types.Exact<{
  input: Types.FdrAgencyInput;
}>;

export type FdrAgencyQuery = {
  __typename?: 'Query';
  fdrAgency?: {
    __typename?: 'FdrAgency';
    id: string;
    name: string;
    phone: string;
    email: string;
    brand: Types.FdrAgencyBrand;
    openStatus: Types.FdrAgencyOpenStatus;
    timezone: string;
    appointmentLink?: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    } | null;
    openingHours?: {
      __typename?: 'FdrOpeningHours';
      monday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      tuesday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      wednesday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      thursday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      friday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      saturday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
      sunday?: {
        __typename?: 'FdrWorkingDay';
        shifts?: Array<{ __typename?: 'FdrShift'; from: any; to: any }> | null;
      } | null;
    } | null;
    webMeta: {
      __typename?: 'FdrMeta';
      title: string;
      description: string;
      index: boolean;
      noFollow: boolean;
      sitemap: boolean;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export const FdrAgencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrAgency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAgencyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAgency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAgency' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAgencyFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrAgencyQuery__
 *
 * To run a query within a React component, call `useFdrAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrAgencyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrAgencyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrAgencyQuery,
    FdrAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FdrAgencyQuery, FdrAgencyQueryVariables>(
    FdrAgencyDocument,
    options
  );
}
export function useFdrAgencyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrAgencyQuery,
    FdrAgencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FdrAgencyQuery, FdrAgencyQueryVariables>(
    FdrAgencyDocument,
    options
  );
}
export type FdrAgencyQueryHookResult = ReturnType<typeof useFdrAgencyQuery>;
export type FdrAgencyLazyQueryHookResult = ReturnType<
  typeof useFdrAgencyLazyQuery
>;
export type FdrAgencyQueryResult = Apollo.QueryResult<
  FdrAgencyQuery,
  FdrAgencyQueryVariables
>;
