import Cookies from 'js-cookie';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { useCurrentRoute } from '@hotelplan/fdr.lib.router.target-page';
import {
  FdrBoostersList,
  FdrBoosterWrapper,
  FdrBoosterCloseButton,
} from '@hotelplan/fdr.regular.fusion.fdr-boosters';
import { expandChat } from '@hotelplan/fdr.regular.fusion.fdr-chat';
import { ClickEventType, trackClicks } from '@hotelplan/libs.tracking';
import { useInactivityTimer } from 'components/domain/timer/useInactivityTimer';
import FdrAgencyLink from 'fdr/components/local/fdr-agency/fdr-agency-link';
import { FdrChat } from 'fdr/components/local/fdr-chat';
import { useHelpOverlayToggleStateContext } from 'fdr/components/local/fdr-help-overlay';
import {
  CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME,
  CUSTOMER_SUPPORT_BOOSTER_TIMEOUT,
} from './fdr-customer-support-booster.constants';

const FdrCustomerSupportBooster: React.FC = () => {
  const [t] = useTranslation('common');
  const i18nKey = 'common:customer_support.booster.text';
  const [showBooster, setShowBooster] = useState(false);
  const { enable: openHelpOverlay } = useHelpOverlayToggleStateContext();
  const { route: currentRoute } = useCurrentRoute<{}, EPageType>();

  function hideBooster() {
    setShowBooster(false);
    Cookies.set(CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME, 'false');
  }

  useEffect(() => {
    if (showBooster && currentRoute?.page) {
      hideBooster();
    }
  }, [currentRoute?.page]);

  useInactivityTimer({
    timeout: CUSTOMER_SUPPORT_BOOSTER_TIMEOUT,
    onIdle: () => {
      if (Cookies.get(CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME) !== 'false') {
        setShowBooster(true);
        Cookies.set(CUSTOMER_SUPPORT_BOOSTER_COOKIE_NAME, 'false');
      }
    },
  });

  if (!showBooster) {
    return null;
  }

  return (
    <FdrBoostersList>
      <FdrBoosterWrapper
        key={'customer-support-booster'}
        data-id={`booster-customer-support`}
        className="booster-wrapper"
      >
        <div className="booster-text">
          <Trans
            i18nKey={i18nKey}
            components={[
              <BsLink
                href={'#'}
                key={'chat'}
                onClick={() => {
                  expandChat();
                  trackClicks({
                    type: ClickEventType.CHAT,
                    payload: { source: 'booster' },
                  });
                  hideBooster();
                }}
              />,
              <BsLink
                href={'#'}
                key={'phone'}
                onClick={() => {
                  hideBooster();
                  openHelpOverlay();
                }}
              />,
              <FdrAgencyLink key={'agency'} onClick={hideBooster} />,
            ]}
          />
        </div>
        <FdrChat />
        <FdrBoosterCloseButton
          onClick={hideBooster}
          variant="iconBtn"
          icon={{
            name: 'close',
          }}
        >
          {t('common:close')}
        </FdrBoosterCloseButton>
      </FdrBoosterWrapper>
    </FdrBoostersList>
  );
};

export default FdrCustomerSupportBooster;
