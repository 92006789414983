import format from 'date-fns/format';
import isEqual from 'fast-deep-equal';
import {
  getReturnDate,
  adjustFdrTravelDates,
  IFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { ITravelRoomsState } from '@hotelplan/components.common.travel-rooms';
import { parseDateStr } from '@hotelplan/libs.utils';
import { IFdrProductSrlControlState } from 'fdr/components/candidate/fdr-search/types/search-form.types';

import {
  GlobalSearchDates,
  GlobalSearchRooms,
  FdrGlobalSearchStateType,
  DATE_FORMAT,
  GlobalSearchTravelType,
} from '@hotelplan/fdr.lib.search.state-config';
import {
  EFdrFlightTravelType,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { FdrProductTravelType } from '@hotelplan/supergraph-api';
import { getDurationsDependsOnType } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';

export function mapTravelTypeGlobalStateToTravelTypeFormState(
  travelType: GlobalSearchTravelType
): U_Extended_TravelType {
  if (travelType) {
    switch (travelType) {
      case GlobalSearchTravelType.Package:
        return {
          fdr: FdrProductTravelType.Package,
        };
      case GlobalSearchTravelType.Hotel:
        return {
          fdr: FdrProductTravelType.HotelOnly,
        };
      case GlobalSearchTravelType.Flight:
        return {
          fdr: EFdrFlightTravelType.Flight,
        };
    }
  }

  return { fdr: null };
}

export function mapTravelTypeFormStateToTravelTypeGlobalState(
  travelType: U_Extended_TravelType
): GlobalSearchTravelType {
  if (travelType?.fdr) {
    switch (travelType?.fdr) {
      case FdrProductTravelType.Package:
        return GlobalSearchTravelType.Package;
      case FdrProductTravelType.HotelOnly:
        return GlobalSearchTravelType.Hotel;
      case EFdrFlightTravelType.Flight:
        return GlobalSearchTravelType.Flight;
    }
  }
  return null;
}

export function mapTravelDatesGlobalStateToTravelDatesFormState(
  travelDates: GlobalSearchDates
): IFdrTravelDatesState {
  const returnDate = parseDateStr(travelDates.rd);
  const departureDate = parseDateStr(travelDates.dd);

  return {
    returnDate,
    departureDate,
    duration: {
      type: `night`,
      duration: getDurationsDependsOnType(travelDates.d),
    },
    searchType: travelDates.st,
    extraDurations: travelDates.ed,
  };
}

export function mapTravelRoomsGlobalStateToTravelRoomsFormState(
  travelRooms: GlobalSearchRooms
): ITravelRoomsState {
  return {
    rooms: travelRooms.r,
    adults: travelRooms.a,
    childrenDobs: travelRooms.cd,
    travellersDistribution: travelRooms.td,
  };
}

export function mapTravelDatesFormStateToTravelDatesGlobalState(
  travelDates: IFdrTravelDatesState
): GlobalSearchDates {
  const returnDate =
    getReturnDate(
      travelDates.departureDate,
      travelDates.returnDate,
      travelDates.duration
    ) || '';

  const departureDate = travelDates
    ? format(travelDates.departureDate, DATE_FORMAT)
    : '';

  return {
    rd: returnDate,
    dd: departureDate,
    d: travelDates.duration,
    st: travelDates.searchType,
    ed: travelDates.extraDurations,
  };
}

export function mapTravelRoomsFormStateToTravelRoomsGlobalState(
  travelRooms: ITravelRoomsState
): GlobalSearchRooms {
  return {
    r: travelRooms.rooms,
    a: travelRooms.adults,
    cd: travelRooms.childrenDobs,
    td: travelRooms.travellersDistribution,
  };
}

export function mapGlobalSearchStateToFormState(
  globalSearchState?: FdrGlobalSearchStateType | null
): IFdrProductSrlControlState {
  const formState = {} as IFdrProductSrlControlState;

  if (!globalSearchState) return formState;

  if (globalSearchState.td) {
    const travelDates = mapTravelDatesGlobalStateToTravelDatesFormState(
      globalSearchState.td
    );

    formState.travelDates = adjustFdrTravelDates(travelDates);
  }

  if (globalSearchState.tt) {
    formState.extended_travelType =
      mapTravelTypeGlobalStateToTravelTypeFormState(globalSearchState.tt);
  }

  if (globalSearchState.tr) {
    formState.travelRooms = mapTravelRoomsGlobalStateToTravelRoomsFormState(
      globalSearchState.tr
    );
  }

  return { ...formState };
}

// direct conversion
function mapSearchControlToGSS(
  state: IFdrProductSrlControlState
): FdrGlobalSearchStateType {
  const gss = {} as FdrGlobalSearchStateType;

  if (!state) return gss;

  if (state.extended_travelType) {
    gss.tt = mapTravelTypeFormStateToTravelTypeGlobalState(
      state.extended_travelType
    );
  }

  if (state.travelRooms) {
    gss.tr = mapTravelRoomsFormStateToTravelRoomsGlobalState(state.travelRooms);
  }

  if (state.travelDates) {
    gss.td = mapTravelDatesFormStateToTravelDatesGlobalState(state.travelDates);
  }

  return gss;
}

export function mapFormStateToGlobalSearchState(
  defaultFormState: IFdrProductSrlControlState,
  formState: IFdrProductSrlControlState,
  currentState?: IFdrProductSrlControlState
): FdrGlobalSearchStateType {
  const resultGss = {} as FdrGlobalSearchStateType;
  const defaultGss = mapSearchControlToGSS(defaultFormState);
  const nextGss = mapSearchControlToGSS(formState);
  const currentGss = mapSearchControlToGSS(currentState);

  if (
    nextGss.tt &&
    (!isEqual(defaultGss.tt, nextGss.tt) || isEqual(currentGss?.tt, nextGss.tt))
  ) {
    resultGss.tt = nextGss.tt;
  }

  if (
    nextGss.tr &&
    (!isEqual(defaultGss.tr, nextGss.tr) || isEqual(currentGss?.tr, nextGss.tr))
  ) {
    resultGss.tr = nextGss.tr;
  }

  if (
    nextGss.td &&
    (!isEqual(defaultGss.td, nextGss.td) || isEqual(currentGss?.td, nextGss.td))
  ) {
    resultGss.td = nextGss.td;
  }

  return resultGss;
}
