import { useTranslation } from 'next-i18next';
import React from 'react';
import { FdrHelpOverlayBoxes } from './fdr-help-overlay-boxes';
import { useHelpOverlayToggleStateContext } from './fdr-help-overlay-toggle-state-context';
import HelpOverlaySkeleton from './fdr-help-overlay.skeleton';
import {
  HelpOverlayClose,
  HelpOverlayWrapper,
} from './fdr-help-overlay.styles';
import { HelpOverlayEvent } from './fdr-help-overlay.types';
import { useFdrHelpOverlayData } from './useFdrHelpOverlayData';

export function openHelpOverlay() {
  window.dispatchEvent(new Event(HelpOverlayEvent.Open));
}

export function onOpenOverlayEvent(callback: () => void): () => void {
  window.addEventListener(HelpOverlayEvent.Open, callback);
  return () => {
    window.removeEventListener(HelpOverlayEvent.Open, callback);
  };
}

export const FdrHelpOverlay: React.FC<{
  onClose?(): void;
}> = ({ onClose }) => {
  const [t] = useTranslation('common');
  const { disable: closeHelpOverlay } = useHelpOverlayToggleStateContext();

  const { boxes, agency, loading, noAgencyId } = useFdrHelpOverlayData();

  if (!boxes || loading) {
    return <HelpOverlaySkeleton />;
  }

  return (
    <HelpOverlayWrapper>
      <FdrHelpOverlayBoxes
        boxes={boxes}
        agency={agency}
        onClickGeneralBox={onClose}
        noAgencyId={noAgencyId}
      />
      <HelpOverlayClose onClick={closeHelpOverlay} icon={{ name: 'close' }}>
        {t('common:close')}
      </HelpOverlayClose>
    </HelpOverlayWrapper>
  );
};
