import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrIconFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-icon.generated';
export type FdrInformationNotificationFragment = {
  __typename?: 'FdrInfoNotification';
  mainText: string;
  icon?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
};

export const FdrInformationNotificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrInformationNotification' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrInfoNotification' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrIcon' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrIconFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
