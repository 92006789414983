import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import { DeviceType, useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrFooterContacts } from 'fdr/components/local/fdr-footer-contacts';
import { FdrFooterFeedback } from 'fdr/components/local/fdr-footer-feedback';
import FdrFooterSkeleton from './fdr-footer.skeleton';
import {
  FooterBoxWrap,
  FooterTopSection,
  FooterTopWrapper,
  ReasonsOfConfidence,
} from './fdr-footer.styles';
import useFdrPageFooter from './useFdrPageFooter';

const FooterBottomDesktop = dynamic<{}>(
  () =>
    import('fdr/components/local/fdr-footer-bottom/fdr-footer-bottom-desktop')
);

const FooterBottomMobile = dynamic<{}>(
  () =>
    import('fdr/components/local/fdr-footer-bottom/fdr-footer-bottom-mobile')
);

const FdrFooter: React.FC = () => {
  const fdrFooterData = useFdrPageFooter();
  const { reasonsOfConfidence, agency, loading, defaultContactInfo } =
    fdrFooterData || {};

  const { serverType, mobile } = useDeviceType();

  const isNotDesktop = serverType !== DeviceType.Desktop;

  const footerBottom = useMemo(() => {
    const isMobileView = typeof window === 'undefined' ? isNotDesktop : mobile;
    return isMobileView ? <FooterBottomMobile /> : <FooterBottomDesktop />;
  }, [mobile, isNotDesktop]);

  return (
    <footer data-id="footer" id="footer">
      {loading ? (
        <FdrFooterSkeleton />
      ) : (
        <>
          {reasonsOfConfidence && agency ? (
            <FooterTopWrapper>
              <FooterTopSection>
                {reasonsOfConfidence && (
                  <ReasonsOfConfidence {...reasonsOfConfidence} />
                )}
                <FooterBoxWrap>
                  {agency && (
                    <FdrFooterContacts
                      agency={agency}
                      defaultContactInfo={defaultContactInfo}
                    />
                  )}
                  <FdrFooterFeedback />
                </FooterBoxWrap>
              </FooterTopSection>
            </FooterTopWrapper>
          ) : null}
          {footerBottom}
        </>
      )}
    </footer>
  );
};

export default React.memo(FdrFooter);
