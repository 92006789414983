import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import { FdrReasonsOfConfidence } from '@hotelplan/fdr.regular.fusion.fdr-footer';

export const FooterTopSection = styled(Container)(({ theme: { media } }) =>
  sx2CssThemeFn({
    marginBottom: 3,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    pt: 5,
    pb: 3,
    [media.tablet]: {
      marginBottom: 5,
      pt: 5,
      pb: 5,
      boxShadow: 'none',
      overflow: 'inherit',
      flexDirection: 'row',
    },
  })
);

export const FooterTopWrapper = styled.div(
  sx2CssThemeFn({
    bg: 'lightGreySixth',
    px: [3, 0],
  })
);

export const FooterBoxWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
  })
);

export const ReasonsOfConfidence = styled(FdrReasonsOfConfidence)(
  ({ theme: { space, media, colors } }) =>
    sx2CssThemeFn({
      p: 4,
      boxShadow: 'none',
      '> div': {
        mb: 0,
      },
      ul: {
        paddingTop: '24px',
        li: {
          marginBottom: space[3],
          fontSize: '16px',
          lineHeight: '24px',
          '&:before': {
            width: '22px',
            height: '22px',
          },
          [media.tablet]: {
            fontSize: '18px',
            lineHeight: '28px',
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
      a: {
        color: colors.defaultText,
        textDecoration: 'underline',
        textDecorationColor: colors.linkUnderlineColor,
        textUnderlineOffset: '6px',
        '&:hover,&:focus': {
          color: colors.defaultText,
          textDecorationColor: colors.defaultText,
        },
      },
    })
);
