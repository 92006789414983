import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';

const NUMBER_OF_SKELETON_ITEMS = 3;

const HelpOverlaySkeletonWrapper = styled(Container)(({ theme }) =>
  sx2CssThemeFn({
    ...theme.helpOverlay.container,
    pb: '84px!important',
  })
);

const HelpOverlaySkeletonBoxWrapper = styled.div<{ count: number }>(
  ({ theme }) => theme.helpOverlay.wrapper
);

const HelpOverlaySkeleton: React.FC = () => {
  return (
    <HelpOverlaySkeletonWrapper>
      {Array.from({ length: NUMBER_OF_SKELETON_ITEMS }, (v, i) => (
        <HelpOverlaySkeletonBoxWrapper
          count={NUMBER_OF_SKELETON_ITEMS}
          key={i}
          className="overlay-box"
        >
          {`${i}` === '2' ? (
            <HelpOverlayItemBigSkeleton />
          ) : (
            <FdrHelpOverlaySkeleton />
          )}
        </HelpOverlaySkeletonBoxWrapper>
      ))}
    </HelpOverlaySkeletonWrapper>
  );
};

export default HelpOverlaySkeleton;

const FdrHelpOverlaySkeleton: React.FC = () => (
  <PlaceholderLoader
    style={{ height: '100%', width: '100%' }}
    width={570}
    height={154}
    viewBox="0 0 570 154"
    uid="helpOverlaySkeleton"
  >
    <rect x="0" y="0" rx="0" ry="0" width="570" height="154" />
  </PlaceholderLoader>
);

const HelpOverlayItemBigSkeleton: React.FC = () => (
  <PlaceholderLoader
    style={{ height: '100%', width: '100%' }}
    width={570}
    height={338}
    viewBox="0 0 570 338"
    uid="helpOverlaySkeletonBig"
  >
    <rect x="0" y="0" rx="0" ry="0" width="570" height="338" />
  </PlaceholderLoader>
);
