import format from 'date-fns/format';
import { convertDOBStrToISOStr } from '@hotelplan/components.common.dob-input';
import {
  getReturnDate,
  IFdrDurationValue,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { TFdrFlatDestination } from '@hotelplan/fdr.regular.fusion.fdr-travel-destination';
import {
  FdrPackageFlightCriteriaInput,
  FdrPriceCriteriaInput,
  FdrProductAttributesCriteriaInput,
  FdrProductSearchCriteriaInput,
  FdrProductTravelType,
  FdrTravellersCriteriaInput,
  FdrTravelPeriodCriteriaInput,
} from '@hotelplan/supergraph-api';
import { DATE_INPUT_FORMAT } from 'fdr/components/candidate/fdr-search/fdr-search.constants';
import {
  EFilterIds,
  IFdrFiltersFormState,
} from 'fdr/components/candidate/fdr-search/types/filters.types';
import {
  IFdrTravelDestinationState,
  TFdrProductSrlControlStateWithType,
} from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { T_Custom_FdrTravelTypeUnit } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';

function isEmptyValue(value: any): boolean {
  if (value === 0 || value === false) return false;
  if (!value) return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'object' && Object.keys(value).length === 0) return true;

  return false;
}

export function filterEmptyCriteria(criteria: Record<string, any>) {
  Object.keys(criteria).forEach(key => {
    if (isEmptyValue(criteria[key])) {
      delete criteria[key];
    } else if (
      typeof criteria[key] === 'object' &&
      !Array.isArray(criteria[key])
    ) {
      filterEmptyCriteria(criteria[key]); // Recursively clean nested objects
      if (Object.keys(criteria[key]).length === 0) {
        delete criteria[key];
      }
    }
  });

  return criteria;
}

export const getDurationsDependsOnType = (drValue: IFdrDurationValue) => {
  return drValue.type === 'week' ? [drValue.duration[0] * 7] : drValue.duration;
};

export const fdrSrlFormToCriteriaPeriod = (
  formState: TFdrProductSrlControlStateWithType
): FdrTravelPeriodCriteriaInput => {
  if (!formState?.travelDates) return null;

  const { departureDate, returnDate, duration } = formState?.travelDates;
  const adjustedReturnDate = getReturnDate(departureDate, returnDate, duration);
  const durations = getDurationsDependsOnType(duration);

  return {
    departureDate: format(departureDate, DATE_INPUT_FORMAT),
    returnDate: adjustedReturnDate,
    durations,
  };
};

export const fdrSrlFormToCriteriaTravellers = (
  formState: TFdrProductSrlControlStateWithType
): FdrTravellersCriteriaInput => {
  if (!formState?.travelRooms) return null;
  const { adults, childrenDobs } = formState?.travelRooms;

  return {
    adults: adults || 0,
    childrenDobs: (childrenDobs || []).map(convertDOBStrToISOStr),
  };
};

function collectFeatures(filtersForm?: IFdrFiltersFormState) {
  const result = [
    ...(filtersForm?.[EFilterIds.MAIN_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.FAMILY_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.BEACH_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.DISTANCE_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.SPORT_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.HOTEL_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.WELLNESS_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.ENVIRONMENT_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.CULTURE_FEATURES]?.value || []),
    ...(filtersForm?.[EFilterIds.OTHER_FEATURES]?.value || []),
  ];

  return result.length ? result : undefined;
}

export function collectPriceCriteria(
  filtersForm?: IFdrFiltersFormState
): FdrPriceCriteriaInput {
  const { min, max } = filtersForm?.[EFilterIds.PRICE]?.value || {};

  return {
    ...(min ? { min: Number(min) } : {}),
    ...(max ? { max: Number(max) } : {}),
  };
}

function collectProductAttributesCriteria(
  filtersForm?: IFdrFiltersFormState
): FdrProductAttributesCriteriaInput {
  return {
    mealTypes: filtersForm?.[EFilterIds.MEAL_TYPES]?.value,
    roomTypes: filtersForm?.[EFilterIds.ROOM_TYPES]?.value,
    accommodationSize: filtersForm?.[EFilterIds.ACCOMMODATION_SIZE]?.value,
    features: collectFeatures(filtersForm),
    minHpRating: filtersForm?.[EFilterIds.HP_RATINGS]?.value,
    minTaRating: filtersForm?.[EFilterIds.TA_RATINGS]?.value,
  };
}

function collectFlightCriteria(
  filtersForm?: IFdrFiltersFormState
): FdrPackageFlightCriteriaInput {
  return {
    departureAirportIatas: filtersForm?.[EFilterIds.DEPARTURE_AIRPORTS]?.value,
    airlineIatas: filtersForm?.[EFilterIds.AIRLINES]?.value,
    maxDuration: Number(filtersForm?.[EFilterIds.FLIGHT_DURATION]?.value),
    maxStops: filtersForm?.[EFilterIds.MAX_STOPS]?.value,
    departureFlightDepartureTime:
      filtersForm?.[EFilterIds.DEPARTURE_FLIGHT_TIME]?.value,
    returnFlightDepartureTime:
      filtersForm?.[EFilterIds.RETURN_FLIGHT_TIME]?.value,
  };
}

export function getFdrFiltersInput(
  filtersForm: IFdrFiltersFormState,
  travelType: T_Custom_FdrTravelTypeUnit
): Pick<
  FdrProductSearchCriteriaInput,
  'productAttributes' | 'flight' | 'price'
> {
  const criteria = {
    flight:
      travelType === FdrProductTravelType.HotelOnly
        ? null
        : collectFlightCriteria(filtersForm),
    price: collectPriceCriteria(filtersForm),
    productAttributes: collectProductAttributesCriteria(filtersForm),
  };

  return filterEmptyCriteria(criteria);
}

export function fdrSrlFormToCriteriaInput(
  formState: IFdrSrlControlState,
  filtersForm?: IFdrFiltersFormState,
  subGeoPublicIds?: string[],
  forceSingleView?: boolean
): FdrProductSearchCriteriaInput {
  if (formState?.type !== 'HOTEL') return null;

  const radiusValue = filtersForm?.[EFilterIds.RADIUS];
  const radiusSearch = radiusValue
    ? {
        centerLatitude: String(radiusValue.center.latitude),
        centerLongitude: String(radiusValue.center.longitude),
        radius: radiusValue.radius,
        label: radiusValue.label,
      }
    : undefined;

  const destinations: FdrProductSearchCriteriaInput['destinations'] = {
    ...(formState?.legacy_travelDestination?.ids || {}),
    radiusSearch,
    ...(subGeoPublicIds
      ? { subGeoPublicIds: subGeoPublicIds.filter(Boolean) }
      : {}),
  };

  const filters = filtersForm
    ? getFdrFiltersInput(filtersForm, formState.extended_travelType.fdr)
    : {};

  const criteria = {
    travellers: fdrSrlFormToCriteriaTravellers(formState),
    rooms: formState?.travelRooms?.rooms || 0,
    period: fdrSrlFormToCriteriaPeriod(formState),
    travelType: formState?.extended_travelType?.fdr as FdrProductTravelType,
    destinations,
    forceSrlSingle: !!forceSingleView,
    ...filters,
  };

  return filterEmptyCriteria(criteria);
}

const getSelectedDestinationsIds = (
  result: IFdrTravelDestinationState['ids'],
  item: TFdrFlatDestination
) => {
  if (!item.id) return result;

  switch (item.__typename) {
    case 'FdrCruise':
    case 'FdrHotel':
    case 'FdrAdventureTravel':
    case 'FdrRoundTrip':
      result.productPublicIds.push(item.id);
      break;

    case 'FdrCountryGroup':
    case 'FdrCountry':
    case 'FdrResort':
    case 'FdrRegion':
    case 'FdrDestination':
    case 'FdrContinent':
      result.geoPublicIds.push(item.id);
      break;

    case 'FdrAirport':
      result.arrivalAirportIatas.push(item.id);
      break;

    case 'FdrTheme':
      result.themePublicIds.push(item.id);
      break;
  }

  return result;
};

export function fdrFlatDestinationToDestinationsCriteria(
  destinations: TFdrFlatDestination[]
): IFdrTravelDestinationState['ids'] {
  return destinations.reduce(getSelectedDestinationsIds, {
    arrivalAirportIatas: [],
    geoPublicIds: [],
    themePublicIds: [],
    productPublicIds: [],
  });
}
