export enum FeatureList {
  BD4TRAVEL_SRL_SORTING = 'bd4travel-srl-sorting',
  MY_ACCOUNT_BOOKING_CANCEL = 'myaccount-booking-cancel',
  MY_ACCOUNT_UPSELL_TRANSFERS = 'myaccount-upsell-transfers',
  SHOW_EXTENDED_OFFER_DETAILS = 'show-extended-offer-details',
  HOLIDAY_FINDER_CONTEST_TEASER = 'holiday-finder-contest-teaser',
  HOLIDAY_FINDER_CONTEST_INFO = 'holiday-finder-contest-info',
  HOLIDAY_FINDER_CONTEST_WISHLIST = 'holiday-finder-contest-wishlist',
  SRL_SINGLE_ONLY = 'srl-single-only',
  SRL_NAVIGATION = 'srl-navigation',
  META_SEARCH_ORL_BEHAVIOR = 'hotelads-behavior',
  CUSTOMER_SUPPORT_BOOSTER = 'customer-support-booster',
  TOP_DESTINATIONS_AUTOSUGGEST_PACKAGE = 'top-destinations-autosuggest-package',
  TOP_DESTINATIONS_AUTOSUGGEST_HOTEL = 'top-destinations-autosuggest-hotel',
  CMS_HP_GEO_PAGE_COMPONENTS_BASED_STRUCTURE = 'cms-hp-geo-page-components-based-structure',
  AUTOSUGGEST_HOTEL_ONLY_DESTINATIONS = 'autosuggest-hotel-only-destinations',
  DESATURATION_FILTER = 'hp-desaturation-filter', // BE
  SPLIT_PAGEVIEW_EVENT = 'split-pageview-event',
  SHOW_KLIMALINK = 'hp-show-klimalink',
  SHOW_ORL_ROOM_AND_BOARD_SELECTION = 'hp-orl-room-and-board-selection',
  PRICE_OVERVIEW_POSITION = 'price-overview-position',
  SRL_MINI_GALLERY = 'srl-mini-gallery',
  FLIGHT_SRL_BUTTON = 'flight-srl-button',
}
